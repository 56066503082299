<template>
  <el-scrollbar>
    <el-tree
      :data="tree"
      :default-expanded-keys="expandKeys"
      @node-expand="handleNodeExpand"
      @node-collapse="handleNodeCollapse"
      node-key="id">
      <template slot-scope="{node, data}">
        <div class="component-item" @click.stop="handleActiveComponent(data)" @dblclick="handleChangeEditId(data)">
          <svg v-if="data.type == 1" focusable="false" class="icon" width="1em" height="1em" fill="currentColor" viewBox="64 64 896 896">
            <path d="M917 211.1l-199.2 24c-6.6.8-9.4 8.9-4.7 13.6l59.3 59.3-226 226-101.8-101.7c-6.3-6.3-16.4-6.2-22.6 0L100.3 754.1a8.03 8.03 0 000 11.3l45 45.2c3.1 3.1 8.2 3.1 11.3 0L433.3 534 535 635.7c6.3 6.2 16.4 6.2 22.6 0L829 364.5l59.3 59.3a8.01 8.01 0 0013.6-4.7l24-199.2c.7-5.1-3.7-9.5-8.9-8.8z"></path>
          </svg>
          <svg v-else focusable="false" class="icon" width="1em" height="1em" fill="currentColor" viewBox="64 64 896 896">
            <path d="M926 164H94c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V196c0-17.7-14.3-32-32-32zm-40 632H134V236h752v560zm-658.9-82.3c3.1 3.1 8.2 3.1 11.3 0l172.5-172.5 114.4 114.5c3.1 3.1 8.2 3.1 11.3 0l297-297.2c3.1-3.1 3.1-8.2 0-11.3l-36.8-36.8a8.03 8.03 0 00-11.3 0L531 565 416.6 450.5a8.03 8.03 0 00-11.3 0l-214.9 215a8.03 8.03 0 000 11.3l36.7 36.9z"></path>
          </svg>
          <el-input
            v-if="editId === data.id"
            ref="editInput"
            v-model="data.description"
            @blur="handleDescriptionChange(data)"
            size="mini"
          />
          <div v-else class="name">{{data.description || data.name}}</div>
          <show-space class="btn-list">
            <el-tooltip effect="light" :content="stateMap[data.locked || 0]" placement="top">
              <i class="c-icon" :class="stateIconMap[data.locked || 0]" @click.stop="handleLockStateChange(data)"/>
            </el-tooltip>
            <el-tooltip effect="light" :content="data.visible === false ? $lang.configurationEditor.hide : $lang.configurationEditor.show" placement="top">
              <div class="icon" @click.stop="handleVisibleChange(data)">
                <svg v-if="data.visible === false" focusable="false" fill="currentColor" aria-hidden="true" viewBox="64 64 896 896">
                  <path d="M942.2 486.2Q889.47 375.11 816.7 305l-50.88 50.88C807.31 395.53 843.45 447.4 874.7 512 791.5 684.2 673.4 766 512 766q-72.67 0-133.87-22.38L323 798.75Q408 838 512 838q288.3 0 430.2-300.3a60.29 60.29 0 000-51.5zm-63.57-320.64L836 122.88a8 8 0 00-11.32 0L715.31 232.2Q624.86 186 512 186q-288.3 0-430.2 300.3a60.3 60.3 0 000 51.5q56.69 119.4 136.5 191.41L112.48 835a8 8 0 000 11.31L155.17 889a8 8 0 0011.31 0l712.15-712.12a8 8 0 000-11.32zM149.3 512C232.6 339.8 350.7 258 512 258c54.54 0 104.13 9.36 149.12 28.39l-70.3 70.3a176 176 0 00-238.13 238.13l-83.42 83.42C223.1 637.49 183.3 582.28 149.3 512zm246.7 0a112.11 112.11 0 01146.2-106.69L401.31 546.2A112 112 0 01396 512z"></path>
                  <path d="M508 624c-3.46 0-6.87-.16-10.25-.47l-52.82 52.82a176.09 176.09 0 00227.42-227.42l-52.82 52.82c.31 3.38.47 6.79.47 10.25a111.94 111.94 0 01-112 112z"></path>
                </svg>
                <svg v-else focusable="false" fill="currentColor" aria-hidden="true" viewBox="64 64 896 896">
                  <path d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z"></path>
                </svg>
              </div>
            </el-tooltip>
          </show-space>
        </div>
      </template>
    </el-tree>
  </el-scrollbar>
</template>
<script>

let timer = null
export default {
  name: "ComponentsConfig",
  
  props: {
    showActive: {
      type: Boolean,
      default: false
    }
  },
  
  data() {
    return {
      tree: [],
      editId: null,
      stateIconMap: {
        0: 'c-unlock',
        1: 'c-lock',
        2: 'c-wufayidong',
        10: 'c-jinyong'
      },
      nextStateMap: {
        0: 1,
        1: 2,
        2: 10,
        10: 0
      },
      expandKeys: []
    }
  },
  
  computed: {
    stateMap() {
      return {
        0: this.$lang.configurationEditor.editable,
        1: this.$lang.configurationEditor.unEditable,
        2: this.$lang.configurationEditor.unMovable,
        10: this.$lang.configurationEditor.disabled
      }
    }
  },
  
  inject: ['eventBus', 'editor'],
  
  created() {
    if (this.showActive) {
      this.eventBus.$on('active', pens => {
        this.loadTree(pens || [])
      })
    } else {
      this.eventBus.$on('openPage', pageData => {
        this.loadTree(pageData.pens || [])
      })
      this.eventBus.$on('updatePens', pens => {
        this.loadTree(pens || [])
      })
    }
  },
  
  methods: {
    loadTree(pens) {
      let penMap = this.editor.engine.store.pens
      pens = pens.filter(pen => !(pen.parentId && penMap[pen.parentId]))
      const parseTree = list => {
        if (list) {
          return list.map(item => {
            if (typeof item === 'string') {
              item = penMap[item]
            }
            let obj = {
              id: item.id,
              name: item.name,
              type: item.type,
              locked: item.locked,
              visible: item.visible,
              description: item.description,
            }
            if (item.children?.length) {
              obj.children = parseTree(item.children)
            }
            return obj
          })
        }
      }
      pens = parseTree(pens)
      this.tree = pens
    },
    
    handleNodeExpand(data) {
      this.expandKeys.push(data.id)
    },
    
    handleNodeCollapse(data) {
      this.expandKeys = this.expandKeys.filter(item => item !== data.id)
    },
    
    handleActiveComponent(data) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        this.eventBus.$emit('penActive', data.id)
      }, 200)
    },
    
    handleChangeEditId(data) {
      clearTimeout(timer)
      this.editId = data.id
      this.$nextTick(() => {
        this.$refs.editInput.focus()
      })
    },
    
    handleDescriptionChange(data) {
      this.editId = null
      this.eventBus.$emit('penChange', {
        id: data.id,
        description: data.description
      })
    },
    
    handleLockStateChange(data) {
      data.locked = this.nextStateMap[data.locked || 0]
      this.eventBus.$emit('penChange', {
        id: data.id,
        locked: data.locked
      })
    },
    
    handleVisibleChange(data) {
      let visible = data.visible || data.visible === undefined
      data.visible = !visible
      const hideChildren = children => {
        if (children?.length) {
          for (const child of children) {
            child.visible = !visible
            hideChildren(child.children)
          }
        }
      }
      hideChildren(data.children)
      let engine= this.editor.engine
      engine.setVisible(engine.findOne(data.id), data.visible)
    }
  }
}
</script>
<style scoped lang="scss">
::v-deep {
  .el-tree-node__content {
    height: 32px;
  }
}

.component-item {
  flex: 1;
  color: #000000;
  line-height: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  padding-right: 16px;
  
  .icon {
    font-size: 16px;
    flex-shrink: 0;
  }
  
  .el-input,
  .name {
    width: 0;
    flex: 1;
    margin: 0 8px;
    
    &::v-deep {
      .el-input__inner {
        padding: 0 8px;
      }
    }
  }
  
  .btn-list {
    flex-shrink: 0;
    
    i {
      font-size: 16px;
    }
    
    .icon {
      width: 16px;
      height: 16px;
      position: relative;
      
      svg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  
  &:hover {
    color: #409EFF;
  }
}
</style>