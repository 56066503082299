<template>
  <el-tabs v-model="activeName" class="page-property">
    <el-tab-pane :label="$lang.configurationEditor.render" name="render">
      <RenderConfig/>
    </el-tab-pane>
    <el-tab-pane :label="$lang.configurationEditor.event" name="event">
      <EventConfig/>
    </el-tab-pane>
    <el-tab-pane :label="$lang.configurationEditor.animation" name="animation">
      <AnimationConfig/>
    </el-tab-pane>
    <el-tab-pane :label="$lang.configurationEditor.data" name="data">
      <FormConfig/>
    </el-tab-pane>
    <el-tab-pane :label="$lang.configurationEditor.node" name="nodeList">
      <ComponentsConfig show-active/>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import RenderConfig from "./RenderConfig";
import EventConfig from "./EventConfig";
import AnimationConfig from "./AnimationConfig";
import FormConfig from "./FormConfig";
import ComponentsConfig from "../ComponentsConfig";
export default {
  name: "SingleComponentProperty",
  
  components: {ComponentsConfig, FormConfig, AnimationConfig, EventConfig, RenderConfig},
  
  props: {
    pen: {},
    penMap: {}
  },
  
  data() {
    return {
      activeName: 'render'
    }
  }
}
</script>
<style scoped lang="scss">
.page-property {
  
  ::v-deep {
    > .el-tabs__header {
      margin: 0;
      
      .el-tabs__nav {
        .el-tabs__item {
          padding: 0 8px;
        }
      }
    }
    
    > .el-tabs__content {
      height: calc(100% - 40px);
      
      > .el-tab-pane {
        height: 100%;
        
        > .el-scrollbar {
          height: 100%;
          
          > .el-scrollbar__wrap {
            overflow-x: auto;
          }
        }
      }
    }
  }
}
</style>