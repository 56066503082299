<template>
  <el-scrollbar>
    <el-form size="mini" class="config-form" label-width="80px" label-position="left">
      <el-collapse v-model="activeNames">
        <el-collapse-item title="WebSocket" name="WebSocket">
          <el-form-item label-width="0" style="margin-bottom: 0">
            <div style="padding: 0 12px">
              <el-input v-model="data.websocket" :placeholder="$lang.configurationEditor.pleaseInputWebsocketUrl"/>
            </div>
          </el-form-item>
        </el-collapse-item>
        <el-collapse-item title="MQTT" name="MQTT">
          <el-form-item :label="$lang.configurationEditor.urlAddress">
            <el-input v-model="data.mqtt"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.clientId">
            <el-input v-model="data.mqttOptions.clientId"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.customClientId" label-width="110px">
            <el-switch v-model="data.mqttOptions.customClientId"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.userName">
            <el-input v-model="data.mqttOptions.username"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.password">
            <el-input v-model="data.mqttOptions.password"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.topics">
            <el-input v-model="data.mqttTopics" :placeholder="$lang.configurationEditor.topicsPlaceholder"/>
          </el-form-item>
        </el-collapse-item>
        <el-collapse-item title="HTTP" name="HTTP">
          <el-form-item :label="$lang.configurationEditor.urlAddress">
            <el-input v-model="data.http"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.timeInterval">
            <el-input-number v-model="data.httpTimeInterval" :precision="0" :min="500" controls-position="right"/>
          </el-form-item>
        </el-collapse-item>
        <el-collapse-item :title="$lang.configurationEditor.socketCbJs" name="消息处理JavaScript">
          <el-form-item :label="$lang.configurationEditor.socketCb">
            <EditScript v-model="data.socketCbJs"/>
          </el-form-item>
        </el-collapse-item>
      </el-collapse>
    </el-form>
  </el-scrollbar>
</template>
<script>
import EditScript from "../../components/EditScript";

export default {
  name: "CommunicationConfig",
  components: {EditScript},
  
  data() {
    return {
      activeNames: ['WebSocket', 'MQTT', 'HTTP', '消息处理JavaScript'],
      data: {
        mqttOptions: {}
      }
    }
  },
  
  watch: {
    data: {
      handler(val) {
        if (this.isEdit) {
          this.eventBus.$emit('communicationChange', JSON.parse(JSON.stringify(val)))
        }
      },
      deep: true
    }
  },
  
  inject: ['eventBus'],
  
  created() {
    this.eventBus.$on('openPage', pageData => {
      this.isEdit = false
      let {websocket, mqtt, mqttTopics, http, httpTimeInterval = 1000, socketCbJs} = pageData
      let  {clientId, customClientId = false, username, password} = pageData.mqttOptions || {}
      this.data = {websocket, mqtt, mqttTopics, mqttOptions: {clientId, customClientId, username, password}, http, httpTimeInterval, socketCbJs}
      this.$nextTick(() => {
        this.isEdit = true
      })
    })
  }
}
</script>
<style scoped lang="scss">
.config-form {
  
  ::v-deep {
    
    .el-collapse-item__header {
      padding-left: 16px;
      padding-right: 8px;
      line-height: 36px;
      height: 36px;
      font-weight: bold;
    }
    
    .el-collapse-item__content {
      padding-bottom: 8px;
    }
    
    .el-form-item {
      margin-bottom: 8px;
      
      .el-form-item__label {
        font-size: 12px;
        padding-right: 8px;
        padding-left: 16px;
      }
      
      .el-form-item__content {
        font-size: 12px;
        padding-right: 12px;
      }
    }
    
    .el-color-picker {
      display: block;
    }
    
    .el-input-number {
      width: 100%;
    }
    
    .el-input__inner {
      text-align: left;
      padding-left: 8px;
    }
  }
}
</style>