<template>
  <el-scrollbar>
    <el-form size="mini" class="config-form" label-width="100px" label-position="left">
      <div style="padding: 16px">
        <el-button type="primary" style="width: 100%" size="mini" @click="handleAddEvent">{{$lang.configurationEditor.addEvent}}</el-button>
      </div>
      <el-collapse v-model="activeNames">
        <template v-for="(event, index) in eventList">
          <el-collapse-item :title="$lang.configurationEditor.event + (index + 1)" :name="'event-' + index">
            <div slot="title" class="event-title">
              <div>事件{{index + 1}}</div>
              <el-popconfirm :title="$lang.confirm.delete" @confirm="handleDeleteEvent(index)">
                <i slot="reference" class="c-icon c-shanchu" @click.stop/>
              </el-popconfirm>
            </div>
            <el-form-item :label="$lang.configurationEditor.eventType">
              <el-select v-model="event.name">
                <el-option value="enter" :label="$lang.configurationEditor.mouseEnter"/>
                <el-option value="leave" :label="$lang.configurationEditor.mouseOut"/>
                <el-option value="active" :label="$lang.configurationEditor.mouseActive"/>
                <el-option value="inactive" :label="$lang.configurationEditor.mouseInactive"/>
                <el-option value="mousedown" :label="$lang.configurationEditor.mouseDown"/>
                <el-option value="mouseup" :label="$lang.configurationEditor.mouseUp"/>
                <el-option value="click" :label="$lang.configurationEditor.mouseClick"/>
                <el-option value="dblclick" :label="$lang.configurationEditor.mouseDoubleClick"/>
                <el-option value="valueUpdate" :label="$lang.configurationEditor.valueUpdate"/>
              </el-select>
            </el-form-item>
            <el-form-item :label="$lang.configurationEditor.doAction">
              <el-select v-model="event.action" @change="handleActionChange(index, $event)">
                <el-option :value="0" :label="$lang.configurationEditor.openLink"/>
                <el-option :value="1" :label="$lang.configurationEditor.changeProp"/>
                <el-option :value="2" :label="$lang.configurationEditor.playAnimation"/>
                <el-option :value="3" :label="$lang.configurationEditor.pauseAnimation"/>
                <el-option :value="4" :label="$lang.configurationEditor.stopAnimation"/>
                <el-option :value="8" :label="$lang.configurationEditor.playVideo"/>
                <el-option :value="9" :label="$lang.configurationEditor.pauseVideo"/>
                <el-option :value="10" :label="$lang.configurationEditor.stopVideo"/>
                <el-option :value="5" :label="$lang.configurationEditor.runJavascript"/>
                <el-option :value="6" :label="$lang.configurationEditor.runGlobalFunction"/>
                <el-option :value="7" :label="$lang.configurationEditor.customMessage"/>
              </el-select>
            </el-form-item>
            <template v-if="event.action === 0">
              <el-form-item :label="$lang.configurationEditor.openLink">
                <el-input v-model="event.value" placeholder="URL"/>
              </el-form-item>
              <el-form-item :label="$lang.configurationEditor.openWay">
                <el-autocomplete size="mini" placeholder="_blank" v-model="event.params" :fetch-suggestions="targetSearch">
	              <div slot-scope="{item}">{{$lang.configurationEditor[item.label]}}</div>
                </el-autocomplete>
              </el-form-item>
            </template>
            <template v-if="event.action === 1">
              <el-form-item :label="$lang.configurationEditor.target">
                <el-input v-model="event.params"/>
              </el-form-item>
              <table style="margin: 0 8px 8px 8px">
                <thead style="font-size: 12px">
                  <tr>
                    <th>{{$lang.configurationEditor.propName}}</th>
                    <th>{{$lang.configurationEditor.propValue}}</th>
                    <th><i class="c-icon c-roundadd" @click="handleAddSetProps(event)"/></th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(row, $index) in event.setProps || []">
                    <tr>
                      <td>
                        <el-autocomplete size="mini" v-model="row.key" @change="handleSetPropsChange(event)" :fetch-suggestions="propertySearch">
                          <div slot-scope="{item}">{{$lang.configurationEditor[item.label]}}</div>
                        </el-autocomplete>
                      </td>
                      <td>
                        <el-input size="mini" v-model="row.value" @change="handleSetPropsChange(event)"/>
                      </td>
                      <td>
                        <el-popconfirm :title="$lang.confirm.delete" @confirm="handleDeleteSetProps(event, $index)">
                          <i slot="reference" class="c-icon c-shanchu"/>
                        </el-popconfirm>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </template>
            <template v-if="[2, 3, 4].includes(event.action)">
              <el-form-item :label="$lang.configurationEditor.animationTarget">
                <el-input v-model="event.value"/>
              </el-form-item>
            </template>
            <template v-if="[8, 9, 10].includes(event.action)">
              <el-form-item :label="$lang.configurationEditor.videoTarget">
                <el-input v-model="event.value"/>
              </el-form-item>
            </template>
            <template v-if="event.action === 5">
              <el-form-item label="JavaScript">
                <EditScript v-model="event.value"/>
              </el-form-item>
              <el-form-item :label="$lang.configurationEditor.functionParams">
                <el-input v-model="event.params"/>
              </el-form-item>
            </template>
            <template v-if="event.action === 6">
              <el-form-item :label="$lang.configurationEditor.functionName">
                <el-input v-model="event.value"/>
              </el-form-item>
              <el-form-item :label="$lang.configurationEditor.functionParams">
                <el-input v-model="event.params"/>
              </el-form-item>
            </template>
            <template v-if="event.action === 7">
              <el-form-item :label="$lang.configurationEditor.messageName">
                <el-input v-model="event.value"/>
              </el-form-item>
              <el-form-item :label="$lang.configurationEditor.messageParams">
                <el-input v-model="event.params"/>
              </el-form-item>
            </template>
            <el-form-item :label="$lang.configurationEditor.triggerCondition">
              <el-select v-model="event.where.type" @change="handleWhereChange(event, $event)">
                <el-option :value="undefined" :label="$lang.configurationEditor.none"/>
                <el-option value="comparison" :label="$lang.configurationEditor.comparison"/>
                <el-option value="alwaysTrue" :label="$lang.configurationEditor.alwaysTrue"/>
                <el-option value="alwaysFalse" :label="$lang.configurationEditor.alwaysFalse"/>
                <el-option value="custom" :label="$lang.configurationEditor.customCodeCondition"/>
              </el-select>
            </el-form-item>
            <template v-if="event.where.type === 'comparison'">
              <el-form-item :label="$lang.configurationEditor.propName">
                <el-autocomplete v-model="event.where.key" :fetch-suggestions="propertySearch">
                  <div slot-scope="{item}">{{$lang.configurationEditor[item.label]}}</div>
                </el-autocomplete>
              </el-form-item>
              <el-form-item :label="$lang.configurationEditor.condition">
                <el-select v-model="event.where.comparison">
                  <el-option value="==" :label="$lang.configurationEditor.equal"/>
                  <el-option value="!=" :label="$lang.configurationEditor.notEqual"/>
                  <el-option value=">" :label="$lang.configurationEditor.graterThan"/>
                  <el-option value=">=" :label="$lang.configurationEditor.graterThanOrEqual"/>
                  <el-option value="<" :label="$lang.configurationEditor.lessThan"/>
                  <el-option value="<=" :label="$lang.configurationEditor.lessThanOrEqual"/>
                  <el-option value="[)" :label="$lang.configurationEditor.between"/>
                  <el-option value="![)" :label="$lang.configurationEditor.notBetween"/>
                  <el-option value="[]" :label="$lang.configurationEditor.belong"/>
                  <el-option value="![]" :label="$lang.configurationEditor.notBelong"/>
                </el-select>
              </el-form-item>
              <el-form-item :label="$lang.configurationEditor.propValue">
                <el-input v-model="event.where.value"/>
              </el-form-item>
            </template>
            <template v-if="event.where.type === 'custom'">
              <el-form-item label="JavaScript">
                <EditScript v-model="event.where.fnJs" />
              </el-form-item>
            </template>
          </el-collapse-item>
        </template>
      </el-collapse>
    </el-form>
  </el-scrollbar>
</template>
<script>

import {deepClone} from "@/components/ConfigurationEngine";
import EditScript from "../../components/EditScript";

const propertyList = [
  {value: 'background', label: 'backgroundColor'},
  {value: 'color', label: 'color'},
  {value: 'text', label: 'text'},
  {value: 'width', label: 'width'},
  {value: 'height', label: 'height'},
  {value: 'visible', label: 'isVisible'},
  {value: 'progress', label: 'progress'},
  {value: 'value', label: 'value'}
]

export default {
  name: "EventConfig",
  components: {EditScript},
  
  data() {
    return {
      id: null,
      activeNames: [],
      eventList: []
    }
  },
  
  watch: {
    eventList: {
      handler(val) {
        if (this.isEdit && this.id) {
          this.eventBus.$emit('penChange', {
            id: this.id,
            events: JSON.parse(JSON.stringify(val || []))
          })
        }
      },
      deep: true
    }
  },
  
  inject: ['eventBus'],
  
  created() {
    this.eventBus.$on('active', pens => {
      if (pens.length === 1) {
        this.isEdit = false
        let events = pens[0].events
        this.id = pens[0].id
        this.eventList = events ? deepClone(events) : []
        this.activeNames = (events || []).map((item, index) => 'event-' + index)
        this.$nextTick(() => {
          this.isEdit = true
        })
      }
    })
  },
  
  methods: {
    handleAddEvent() {
      this.eventList.push({
        where: {}
      })
      this.activeNames.push('event-' + (this.eventList.length - 1))
    },
  
    handleDeleteEvent(index) {
      this.eventList.splice(index, 1)
    },
  
    handleActionChange(index, action) {
      let {where, name} = this.eventList[index]
      let event = {where, name, action}
      if (action === 1) {
        event.setProps = [{key: '', value: ''}]
        this.handleSetPropsChange(event)
      }
      this.$set(this.eventList, index, event)
    },
    
    handleAddSetProps(event) {
      event.setProps.push({key: '', value: ''})
      this.handleSetPropsChange(event)
    },
  
    handleDeleteSetProps(event, $index) {
      let setProps = event.setProps
      if (setProps.length === 1) {
        this.$message.warning('至少保留一条属性')
      } else {
        setProps.splice($index, 1)
        this.handleSetPropsChange(event)
      }
    },
    
    handleSetPropsChange(event) {
      let setProps = event.setProps
      if (setProps && setProps.length) {
        let value = {}
        let newSetProps = []
        setProps.forEach(item => {
          if (value[item.key] === undefined) {
            newSetProps.push(item)
          }
          let itemValue = item.value
          if (!isNaN(itemValue)) {
            itemValue = Number(itemValue)
          } else if (['true', 'false'].includes(itemValue)) {
            itemValue = itemValue === 'true'
          }
          value[item.key] = itemValue
        })
        this.$set(event, 'value', value)
        this.$set(event, 'setProps', newSetProps)
      }
    },
    
    handleWhereChange(event, type) {
      if (type) {
        if (type === 'alwaysTrue') {
          this.$set(event, 'where', {type, fnJs: 'return true'})
        } else if (type === 'alwaysFalse') {
          this.$set(event, 'where', {type, fnJs: 'return false'})
        } else {
          this.$set(event, 'where', {type})
        }
      } else {
        this.$set(event, 'where', {})
      }
    },
    
    propertySearch(queryString, callback) {
      callback(propertyList)
    },
	  
    targetSearch(queryString, callback) {
      callback([{value: '_blank', label: 'blank'}, {value: '_self', label: 'self'}])
    }
  }
}
</script>
<style scoped lang="scss">
.config-form {
  
  ::v-deep {
    
    .el-collapse-item__header {
      padding-left: 16px;
      padding-right: 8px;
      line-height: 36px;
      height: 36px;
      font-weight: bold;
      
      .event-title {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 16px;
        
        .c-icon {
          font-size: 14px;
        }
      }
    }
    
    .el-collapse-item__content {
      padding-bottom: 8px;
    }
    
    .el-form-item {
      margin-bottom: 8px;
      
      .el-form-item__label {
        font-size: 12px;
        padding-right: 8px;
        padding-left: 16px;
      }
      
      .el-form-item__content {
        font-size: 12px;
        padding-right: 12px;
      }
    }
    
    .el-input-number {
      width: 100%;
    }
    
    .el-input__inner {
      text-align: left;
      padding-left: 8px;
    }
  }
}
</style>