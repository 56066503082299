<template>
  <el-scrollbar>
    <el-form size="mini" class="config-form" label-width="96px" label-position="left">
      <el-collapse v-model="activeNames">
        <el-collapse-item :title="$lang.configurationEditor.alignPens" name="alignPens">
          <div class="align-buttons">
            <template v-for="button in alignPensButtons">
              <el-tooltip :content="button.title" placement="top" effect="light">
                <i :class="button.icon" @click="button.handler"/>
              </el-tooltip>
            </template>
          </div>
        </el-collapse-item>
        <el-collapse-item :title="$lang.configurationEditor.alignFirst" name="alignFirst">
          <div class="align-buttons">
            <template v-for="button in alignFirstButtons">
              <el-tooltip :content="button.title" placement="top" effect="light">
                <i :class="button.icon" @click="button.handler"/>
              </el-tooltip>
            </template>
          </div>
        </el-collapse-item>
        <el-collapse-item :title="$lang.configurationEditor.autoLayout" name="layout">
          <el-form-item :label="$lang.configurationEditor.maxWidth">
            <el-input-number v-model="maxWidth" :precision="0" :min="1" controls-position="right" :placeholder="$lang.configurationEditor.autoFit"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.space">
            <el-input-number v-model="space" :precision="0" :min="0" controls-position="right"/>
          </el-form-item>
          <el-form-item label-width="0">
            <div style="padding: 0 4px 0 16px">
              <el-button type="primary" style="width: 100%" @click="handleLayout">{{$lang.configurationEditor.startLayout}}</el-button>
            </div>
          </el-form-item>
        </el-collapse-item>
        <el-collapse-item :title="$lang.configurationEditor.style" name="style">
          <el-form-item :label="$lang.configurationEditor.width">
            <el-input-number v-model="data.width" controls-position="right"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.height">
            <el-input-number v-model="data.height" controls-position="right"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.lineStyle">
            <ChooseLineStyle v-model="data.dash" @changeDash="handleChangeDash"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.lineWidth">
            <el-input-number v-model="data.lineWidth" controls-position="right" :min="0"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.lineCap">
            <el-select v-model="data.lineCap">
              <el-option value="butt" :label="$lang.configurationEditor.default"/>
              <el-option value="round" :label="$lang.configurationEditor.round"/>
              <el-option value="square" :label="$lang.configurationEditor.square"/>
            </el-select>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.lineJoin">
            <el-select v-model="data.lineJoin">
              <el-option value="miter" :label="$lang.configurationEditor.default"/>
              <el-option value="bevel" :label="$lang.configurationEditor.bevel"/>
              <el-option value="round" :label="$lang.configurationEditor.round"/>
            </el-select>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.lineGradient">
            <el-select v-model="data.strokeType">
              <el-option :value="0" :label="$lang.configurationEditor.none"/>
              <el-option :value="1" :label="$lang.configurationEditor.linearGradient"/>
            </el-select>
          </el-form-item>
          <template v-if="data.strokeType">
            <el-form-item :label="$lang.configurationEditor.startColor">
              <color-picker v-model="data.lineGradientFromColor" show-alpha/>
            </el-form-item>
            <el-form-item :label="$lang.configurationEditor.endColor">
              <color-picker v-model="data.lineGradientToColor" show-alpha/>
            </el-form-item>
            <el-form-item :label="$lang.configurationEditor.gradientAngle">
              <el-input-number v-model="data.lineGradientAngle" controls-position="right" :min="0"/>
            </el-form-item>
          </template>
          <el-form-item v-else :label="$lang.configurationEditor.color">
            <color-picker v-model="data.color" show-alpha/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.alpha">
            <el-input-number v-model="data.globalAlpha" controls-position="right" :min="0" :step="0.1"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.background">
            <el-select v-model="data.bkType">
              <el-option :value="0" :label="$lang.configurationEditor.none"/>
              <el-option :value="1" :label="$lang.configurationEditor.linearGradient"/>
              <el-option :value="2" :label="$lang.configurationEditor.radialGradient"/>
            </el-select>
          </el-form-item>
          <template v-if="data.bkType">
            <el-form-item :label="$lang.configurationEditor.startColor">
              <color-picker v-model="data.gradientFromColor" show-alpha/>
            </el-form-item>
            <el-form-item :label="$lang.configurationEditor.endColor">
              <color-picker v-model="data.gradientToColor" show-alpha/>
            </el-form-item>
            <el-form-item :label="$lang.configurationEditor.gradientAngle" v-if="data.bkType === 1">
              <el-input-number v-model="data.gradientAngle" controls-position="right" :min="0"/>
            </el-form-item>
            <el-form-item :label="$lang.configurationEditor.gradientRadius" v-else>
              <el-input-number v-model="data.gradientRadius" controls-position="right" :min="0"/>
            </el-form-item>
          </template>
        </el-collapse-item>
        <el-collapse-item :title="$lang.configurationEditor.textWord" name="text">
          <el-form-item :label="$lang.configurationEditor.font">
            <el-autocomplete
              v-model="data.fontFamily"
              :fetch-suggestions="fontFamilySearch"
            />
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.fontSize">
            <el-input-number v-model="data.fontSize" controls-position="right" :min="1"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.textColor">
            <color-picker v-model="data.textColor" show-alpha/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.hoverTextColor">
            <color-picker v-model="data.hoverTextColor" show-alpha/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.activeTextColor">
            <color-picker v-model="data.activeTextColor" show-alpha/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.textBackgroundColor">
            <color-picker v-model="data.textBackground" show-alpha/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.italic">
            <el-select v-model="data.fontStyle">
              <el-option value="normal" :label="$lang.configurationEditor.normal"/>
              <el-option value="italic" :label="$lang.configurationEditor.italic"/>
            </el-select>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.bold">
            <el-select v-model="data.fontWeight">
              <el-option value="normal" :label="$lang.configurationEditor.normal"/>
              <el-option value="bold" :label="$lang.configurationEditor.bold"/>
            </el-select>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.horizontalAlign">
            <el-select v-model="data.textAlign">
              <el-option value="left" :label="$lang.configurationEditor.alignLeft"/>
              <el-option value="center" :label="$lang.configurationEditor.center"/>
              <el-option value="right" :label="$lang.configurationEditor.alignRight"/>
            </el-select>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.verticalAlign">
            <el-select v-model="data.textBaseline">
              <el-option value="top" :label="$lang.configurationEditor.alignTop"/>
              <el-option value="middle" :label="$lang.configurationEditor.center"/>
              <el-option value="bottom" :label="$lang.configurationEditor.alignBottom"/>
            </el-select>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.lineHeight">
            <el-input-number v-model="data.lineHeight" controls-position="right" :min="0"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.textWrap">
            <el-select v-model="data.whiteSpace">
              <el-option value="" :label="$lang.configurationEditor.default"/>
              <el-option value="nowrap" :label="$lang.configurationEditor.noWrap"/>
              <el-option value="pre-line" :label="$lang.configurationEditor.preLine"/>
              <el-option value="break-all" :label="$lang.configurationEditor.breakAll"/>
            </el-select>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.offsetHorizontal">
            <el-input-number v-model="data.textLeft" controls-position="right"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.offsetVertical">
            <el-input-number v-model="data.textTop" controls-position="right"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.ellipsis">
            <el-switch v-model="data.ellipsis"/>
          </el-form-item>
        </el-collapse-item>
      </el-collapse>
    </el-form>
  </el-scrollbar>
</template>
<script>
import ChooseLineStyle from "../../components/ChooseLineStyle";
import fontFamilyList from "../../data/fontFamilyList";

const watchProps = [
  'width',
  'height',
  'lineStyle',
  'lineDash',
  'lineWidth',
  'lineCap',
  'lineJoin',
  'strokeType',
  'lineGradientFromColor',
  'lineGradientToColor',
  'lineGradientAngle',
  'color',
  'globalAlpha',
  'bkType',
  'gradientFromColor',
  'gradientToColor',
  'gradientAngle',
  'gradientRadius',
  'fontFamily',
  'fontSize',
  'textColor',
  'hoverTextColor',
  'activeTextColor',
  'textBackground',
  'fontStyle',
  'fontWeight',
  'textAlign',
  'textBaseline',
  'lineHeight',
  'whiteSpace',
  'textLeft',
  'textTop',
  'ellipsis'
]

export default {
  name: "LayoutConfig",
  components: {ChooseLineStyle},
  data() {
    return {
      activeNames: ['alignPens', 'alignFirst', 'layout', 'style', 'text'],
      maxWidth: undefined,
      space: 30,
      data: this.initFormData()
    }
  },
  
  computed: {
    alignPensButtons() {
      return [
        {
          title: this.$lang.configurationEditor.alignLeft,
          icon: 'c-icon c-align-left',
          handler: () => {
            this.handleAlign('left')
          }
        },
        {
          title: this.$lang.configurationEditor.alignRight,
          icon: 'c-icon c-align-right',
          handler: () => {
            this.handleAlign('right')
          }
        },
        {
          title: this.$lang.configurationEditor.alignTop,
          icon: 'c-icon c-align-top',
          handler: () => {
            this.handleAlign('top')
          }
        },
        {
          title: this.$lang.configurationEditor.alignBottom,
          icon: 'c-icon c-align-bottom',
          handler: () => {
            this.handleAlign('bottom')
          }
        },
        {
          title: this.$lang.configurationEditor.alignMiddle,
          icon: 'c-icon c-align-center',
          handler: () => {
            this.handleAlign('center')
          }
        },
        {
          title: this.$lang.configurationEditor.alignCenter,
          icon: 'c-icon c-align-middle',
          handler: () => {
            this.handleAlign('middle')
          }
        },
        {
          title: this.$lang.configurationEditor.horizontalBetween,
          icon: 'c-icon c-horizontal-between',
          handler: () => {
            this.handleAlign('horizontal-between')
          }
        },
        {
          title: this.$lang.configurationEditor.verticalBetween,
          icon: 'c-icon c-vertical-between',
          handler: () => {
            this.handleAlign('vertical-between')
          }
        }
      ]
    },
    alignFirstButtons() {
      return [
        {
          title: this.$lang.configurationEditor.alignLeft,
          icon: 'c-icon c-align-left',
          handler: () => {
            this.handleAlign('left', false)
          }
        },
        {
          title: this.$lang.configurationEditor.alignRight,
          icon: 'c-icon c-align-right',
          handler: () => {
            this.handleAlign('right', false)
          }
        },
        {
          title: this.$lang.configurationEditor.alignTop,
          icon: 'c-icon c-align-top',
          handler: () => {
            this.handleAlign('top', false)
          }
        },
        {
          title: this.$lang.configurationEditor.alignBottom,
          icon: 'c-icon c-align-bottom',
          handler: () => {
            this.handleAlign('bottom', false)
          }
        },
        {
          title: this.$lang.configurationEditor.alignMiddle,
          icon: 'c-icon c-align-center',
          handler: () => {
            this.handleAlign('center', false)
          }
        },
        {
          title: this.$lang.configurationEditor.alignCenter,
          icon: 'c-icon c-align-middle',
          handler: () => {
            this.handleAlign('middle', false)
          }
        },
        {
          title: this.$lang.configurationEditor.sameSize,
          icon: 'c-icon c-yiyang',
          handler: () => {
            this.handleAlign('same', false)
          }
        },
        {
          title: this.$lang.configurationEditor.formatBrush,
          icon: 'c-icon c-geshishua',
          handler: () => {
            this.handleAlign('format', false)
          }
        }
      ]
    }
  },
  
  inject: ['eventBus', 'editor'],
  
  created() {
    this.eventBus.$on('active', pens => {
      if (pens.length < 2) {
        this.edit = false
        this.data = this.initFormData()
        this.$nextTick(() => {
          this.edit = true
        })
      }
    })
    this.watchers = watchProps.map(prop => {
      return this.$watch(`data.${prop}`, val => {
        if (this.edit) {
          this.handleChange(prop, val)
        }
      })
    })
  },
  
  beforeDestroy() {
    this.watchers.forEach(unwatch => unwatch())
  },
  
  methods: {
    initFormData() {
      return {
        dash: 0,
        lineWidth:  1,
        lineCap: 'butt',
        lineJoin: 'miter',
        strokeType: 0,
        globalAlpha: 1,
        bkType: 0,
        fontFamily: '"Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", "Helvetica, Aria"',
        fontSize: 12,
        fontStyle: 'normal',
        fontWeight: 'normal',
        textAlign: 'center',
        textBaseline: 'middle',
        whiteSpace: '',
        ellipsis: false
      }
    },
    
    handleLayout() {
      this.eventBus.$emit('layout', {
        maxWidth: this.maxWidth,
        space: this.space
      })
    },
    
    handleAlign(position, isAll = true) {
      this.eventBus.$emit('align', {
        position,
        isAll
      })
    },
    
    handleChangeDash(val) {
      this.$set(this.data, 'lineDash', val)
      this.handleChange('lineDash')
    },
  
    fontFamilySearch(queryString, callback) {
      callback(fontFamilyList.map(item => ({value: item})))
    },
    
    handleChange(prop, val) {
      let engine = this.editor.engine
      let ids = engine.store.active.map(item => item.id)
      engine.setValueBatch({[prop]: val}, ids)
    }
  }
}
</script>
<style scoped lang="scss">
.config-form {
  
  ::v-deep {
  
    .el-collapse-item__header {
      padding-left: 16px;
      padding-right: 8px;
      line-height: 36px;
      height: 36px;
      font-weight: bold;
    }
  
    .el-collapse-item__content {
      padding-bottom: 8px;
    }
  
    .el-form-item {
      margin-bottom: 8px;
    
      .el-form-item__label {
        font-size: 12px;
        padding-right: 8px;
        padding-left: 16px;
      }
    
      .el-form-item__content {
        font-size: 12px;
        padding-right: 12px;
      }
    }
    
    .el-input__inner {
      text-align: left;
      padding-left: 8px;
    }
  
    .el-input-number {
      width: 100%;
    }
  }
  
  .align-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    
    i {
      cursor: pointer;
      
      &:hover {
        color: #409EFF;
      }
    }
  }
}
</style>