<template>
  <el-scrollbar>
    <el-form size="mini" class="config-form" label-width="100px" label-position="left">
      <el-collapse v-model="activeNames">
        <el-collapse-item :title="$lang.configurationEditor.animation" name="animation">
          <template v-if="data.type === 1">
            <el-form-item :label="$lang.configurationEditor.animationType">
              <el-select v-model="data.lineAnimateType" :placeholder="$lang.configurationEditor.animationWaterFlow" clearable>
                <el-option :value="0" :label="$lang.configurationEditor.animationWaterFlow"/>
                <el-option :value="1" :label="$lang.configurationEditor.animationWaterDotFlow"/>
                <el-option :value="2" :label="$lang.configurationEditor.animationWaterDot"/>
              </el-select>
            </el-form-item>
            <el-form-item :label="$lang.configurationEditor.animationDash" v-if="data.lineAnimateType === 1">
              <ChooseAnimateLineStyle v-model="data.animateDash" @changeDash="$set(data, 'animateLineDash', $event)"/>
            </el-form-item>
            <el-form-item :label="$lang.configurationEditor.animationDotSize" v-if="data.lineAnimateType === 2">
              <el-input-number v-model="data.animateDotSize" :min="6" controls-position="right" placeholder="最小为6"/>
            </el-form-item>
            <el-form-item :label="$lang.configurationEditor.animationColor">
              <color-picker v-model="data.animateColor" show-alpha/>
            </el-form-item>
            <el-form-item :label="$lang.configurationEditor.animationSpan">
              <el-slider v-model="data.animateSpan" :min="1" :max="5" :step="1"/>
            </el-form-item>
            <el-form-item :label="$lang.configurationEditor.animationReverse">
              <el-switch v-model="data.animateReverse"/>
            </el-form-item>
          </template>
          <template v-else>
            <el-form-item :label="$lang.configurationEditor.animationType">
              <el-select v-model="data.animateType" @change="handleAnimationTypeChange">
                <template v-for="animation in animationList">
                  <el-option :value="animation.value" :label="$lang.configurationEditor[animation.label]"/>
                </template>
                <el-option value="custom" :label="$lang.configurationEditor.custom"/>
              </el-select>
            </el-form-item>
            <el-form-item :label="$lang.configurationEditor.animationDuration">
              <el-input v-model="data.showDuration" readonly/>
            </el-form-item>
            <el-form-item v-if="data.animateType === 'custom'" :label="$lang.configurationEditor.animationFrame">
              <EditAnimationFrames v-model="data.frames" @changeDuration="$set(data, 'showDuration', $event)"/>
            </el-form-item>
          </template>
          <el-form-item :label="$lang.configurationEditor.animationCycle">
            <el-input-number v-model="data.animateCycle" :precision="0" :min="0" :placeholder="$lang.configurationEditor.animationDefaultCycle" controls-position="right"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.nextAnimation">
            <el-input v-model="data.nextAnimate" placeholder="tag"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.autoPlay">
            <el-switch v-model="data.autoPlay"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.keepAnimationState">
            <el-switch v-model="data.keepAnimateState"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.animationLinear">
            <el-switch v-model="data.linear"/>
          </el-form-item>
          <el-form-item label-width="0">
            <ShowSpace style="padding-left: 16px">
              <el-button type="primary" @click="handleExecute('startAnimate')">{{$lang.configurationEditor.play}}</el-button>
              <el-button type="primary" @click="handleExecute('pauseAnimate')">{{$lang.configurationEditor.pause}}</el-button>
              <el-button type="primary" @click="handleExecute('stopAnimate')">{{$lang.configurationEditor.stop}}</el-button>
            </ShowSpace>
          </el-form-item>
        </el-collapse-item>
        <el-collapse-item :title="$lang.configurationEditor.mouseMark" name="mark">
          <el-form-item :label="$lang.configurationEditor.titleContent">
            <EditScript v-model="data.title" :title="$lang.configurationEditor.titleContentPlaceholder" language="Markdown"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.titleContentCbJs">
            <EditScript v-model="data.titleFnJs"/>
          </el-form-item>
        </el-collapse-item>
      </el-collapse>
    </el-form>
  </el-scrollbar>
</template>
<script>
import EditScript from "@/components/ConfigurationEditor/components/EditScript";
import EditAnimationFrames from "@/components/ConfigurationEditor/components/EditAnimationFrames";
import ChooseAnimateLineStyle from "@/components/ConfigurationEditor/components/ChooseAnimateLineStyle";

const propertyList = [
  'id',
  'type',
  'animateType',
  'frames',
  'animateCycle',
  'nextAnimate',
  'autoPlay',
  'keepAnimateState',
  'linear',
  'showDuration',
  
  'title',
  'titleFnJs',
  
  'lineAnimateType',
  'animateColor',
  'animateDash',
  'animateLineDash',
  'animateDotSize',
  'animateSpan',
  'animateReverse',
]

export default {
  name: "AnimationConfig",
  components: {ChooseAnimateLineStyle, EditAnimationFrames, EditScript},
  
  data() {
    return {
      activeNames: ['animation', 'mark'],
      animationList: [
        {
          value: 'none',
          label: 'none',
          frames: []
        },
        {
          value: 'upDown',
          label: 'animationUpDown',
          frames: [{"y": -10, "duration": 100}, {"y": 0, "duration": 100}, {"y": -10, "duration": 200}]
        },
        {
          value: 'leftRight',
          label: 'animationLeftRight',
          frames: [{"x": -10, "duration": 100}, {"x": 10, "duration": 80}, {"x": -10, "duration": 50}, {"x": 10, "duration": 30}, {"x": 0, "duration": 300}]
        },
        {
          value: 'heart',
          label: 'animationHeart',
          frames: [{"scale": 1.1, "duration": 100}, {"scale": 1, "duration": 400}]
        },
        {
          value: 'rotate',
          label: 'animationRotate',
          frames: [{"rotate": 360, "duration": 1000}]
        }
      ],
      data: {}
    }
  },
  
  watch: {
    data: {
      handler(val) {
        if (val.id && this.isEdit) {
          this.eventBus.$emit('penChange', Object.assign({}, val))
        }
      },
      deep: true
    }
  },
  
  inject: ['eventBus'],
  
  created() {
    this.eventBus.$on('active', pens => {
      if (pens.length === 1) {
        this.isEdit = false
        let pen = pens[0]
        let data = {}
        propertyList.forEach(key => {
          if (pen[key] !== undefined) {
            data[key] = pen[key]
          }
        })
        this.data = data
        this.$nextTick(() => {
          this.isEdit = true
        })
      }
    })
  },
  
  methods: {
    handleAnimationTypeChange(animateType) {
      if (animateType !== 'custom') {
        let frames = this.animationList.find(item => item.value === animateType).frames
        this.$set(this.data, 'frames', JSON.parse(JSON.stringify(frames)))
      }
      let showDuration = 0;
      (this.data.frames || []).forEach(item => {
        showDuration += item.duration
      })
      this.$set(this.data, 'showDuration', showDuration)
    },
    
    handleExecute(method) {
      this.eventBus.$emit('animation', {
        id: this.data.id,
        method
      })
    }
  }
}
</script>
<style scoped lang="scss">
.config-form {
  
  ::v-deep {
    
    .el-collapse-item__header {
      padding-left: 16px;
      padding-right: 8px;
      line-height: 36px;
      height: 36px;
      font-weight: bold;
    }
    
    .el-collapse-item__content {
      padding-bottom: 8px;
    }
    
    .el-form-item {
      margin-bottom: 8px;
      
      .el-form-item__label {
        font-size: 12px;
        padding-right: 8px;
        padding-left: 16px;
      }
      
      .el-form-item__content {
        font-size: 12px;
        padding-right: 12px;
      }
    }
  
    .el-color-picker {
      display: block;
    }
    
    .el-input-number {
      width: 100%;
    }
    
    .el-input__inner {
      text-align: left;
      padding-left: 8px;
    }
    
    .el-slider {
      margin: 0 5px;
      
      .el-slider__runway {
        margin: 11px 0;
      }
    }
  }
}
</style>