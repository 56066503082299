<template>
  <el-select v-model="computedValue" clearable class="choose-line">
    <div slot="prefix">
      <div v-if="value !== undefined && value !== null" :class="'line-' + value"/>
    </div>
    <el-option :value="0">
      <div style="margin-top: 16px;" class="line-0"/>
    </el-option>
    <el-option :value="2">
      <div style="margin-top: 16px;" class="line-2"/>
    </el-option>
    <el-option :value="3">
      <div style="margin-top: 16px;" class="line-3"/>
    </el-option>
  </el-select>
</template>
<script>
const dashList = [
  null,
  null,
  [10, 10],
  [10, 10, 2, 10]
]

export default {
  name: "ChooseAnimateLineStyle",
  
  model: {
    prop: 'value',
    event: 'change'
  },
  
  props: {
    value: {}
  },
  
  computed: {
    computedValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('change', val)
        this.$emit('changeDash', dashList[val] ? [...dashList[val]] : null)
      }
    }
  }
}
</script>
<style scoped lang="scss">
.choose-line {
  ::v-deep {
    .el-input__prefix {
      display: block;
      height: 26px;
      left: 2px;
      top: 1px;
      right: 32px;
      background-color: white;
      padding-left: 8px;
      cursor: pointer;
      
      [class^='line-'] {
        margin-top: 12px;
      }
    }
  }
}

[class^='line-'] {
  width: 100%;
  height: 2px;
}

.line-0 {
  background-size: 10px 2px;
  background-repeat: repeat-x;
  background-image: linear-gradient(to right, #000000 0%, #000000 50%, transparent 50%);
}

.line-2 {
  background-size: 20px 2px;
  background-repeat: repeat-x;
  background-image: linear-gradient(to right, #000000 0%, #000000 50%, transparent 50%);
}

.line-3 {
  background-size: 30px 2px;
  background-repeat: repeat-x;
  background-image: linear-gradient(to right, #000000 0%, #000000 30%, transparent 30%, transparent 60%, #000000 60%, #000000 70%, transparent 70%);
}
</style>