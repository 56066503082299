<template>
  <div style="height: 100%">
    <div class="search">
      <el-input :placeholder="$lang.configurationEditor.searchComponent" size="mini" v-model="searchKey" clearable/>
    </div>
    <el-scrollbar style="height: calc(100% - 44px)" wrap-style="overflow-x: auto;">
      <el-collapse v-model="activeNames" class="component-list-wrapper" v-loading="loading">
        <el-collapse-item :title="$lang.configurationEditor.usuallyComponent" name="常用组件">
          <div class="component-list">
            <template v-for="component in filterComponents(usuallyComponents)">
              <el-tooltip effect="light" :content="component.name" placement="top" :enterable="false" transition="none">
                <div
                  class="component-item"
                  draggable="true"
                  @dragstart="handleComponentDrag($event, component)">
                  <i v-if="(component.icon || '').indexOf('c-icon ') === 0" :class="component.icon"/>
                  <img v-else :src="component.icon"/>
                </div>
              </el-tooltip>
            </template>
          </div>
        </el-collapse-item>
        <template v-for="group in componentTree">
          <el-collapse-item :title="group.name" :name="group.name">
            <div class="component-list">
              <template v-for="component in filterComponents(group.children)">
                <el-tooltip effect="light" :content="component.name" placement="top" :enterable="false" transition="none">
                  <div
                    class="component-item"
                    draggable="true"
                    @dragstart="handleComponentDrag($event, component)">
                    <i v-if="(component.icon || '').indexOf('c-icon ') === 0" :class="component.icon"/>
                    <img v-else :src="component.icon"/>
                  </div>
                </el-tooltip>
              </template>
            </div>
          </el-collapse-item>
        </template>
      </el-collapse>
    </el-scrollbar>
  </div>
</template>
<script>
import {getTree} from "@/api/componentManage";

function getUsuallyComponents() {
  let usuallyComponents = localStorage.getItem("usuallyComponents")
  if (usuallyComponents) {
    try {
      usuallyComponents = JSON.parse(usuallyComponents)
    } catch (e) {
      usuallyComponents = []
    }
  } else {
    usuallyComponents = []
  }
  return usuallyComponents
}

export default {
  name: "ComponentMenu",
  
  data() {
    return {
      loading: false,
      searchKey: '',
      activeNames: ['常用组件', '基本形状'],
      usuallyComponents: getUsuallyComponents(),
      componentTree: [],
      componentMap: {}
    }
  },
  
  created() {
    this.getComponentsData()
  },
  
  methods: {
    getComponentsData() {
      this.loading = true
      getTree().then(res => {
        this.componentTree = res.data
        let componentMap = {}
        res.data.forEach(group => {
          group.children.forEach(component => {
            componentMap[component.id] = component
          })
        })
        this.componentMap = componentMap
      }).finally(() => {
        this.loading = false
      })
    },
    
    filterComponents(list) {
      if (this.searchKey) {
        list =  list.filter(item => item.name.indexOf(this.searchKey) > -1)
      }
      return list.filter(item => !!this.componentMap[item.id])
    },
  
    addUsuallyComponent(component) {
      if (!component) {
        return
      }
      let usuallyComponents = getUsuallyComponents()
      usuallyComponents = usuallyComponents.filter(item => item.name !== component.name)
      usuallyComponents.splice(0, 0, component)
      usuallyComponents = usuallyComponents.slice(0, 8)
      localStorage.setItem("usuallyComponents", JSON.stringify(usuallyComponents))
      this.usuallyComponents = usuallyComponents
    },
    
    handleComponentDrag(e, component) {
      e.dataTransfer.setData("text/plain", component.data);
      this.addUsuallyComponent(component)
    }
  }
}
</script>
<style scoped lang="scss">
.component-list-wrapper {
  ::v-deep {
    .el-collapse-item__header {
      padding-left: 16px;
      line-height: 36px;
      height: 36px;
    }
    
    .el-collapse-item__content {
      padding-bottom: 8px;
    }
  }
}

.search {
  padding: 8px;
}

.component-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px;
  
  .component-item {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    
    .c-icon {
      font-size: 25px;
    }
    
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
</style>