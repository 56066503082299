export default [
  'serif',
  'sans-serif',
  'monospace',
  'cursive',
  'fantasy',
  'system-ui',
  'emoji',
  'math',
  'fangsong',
  '宋体',
  '微软雅黑',
  '黑体',
  '楷体'
]