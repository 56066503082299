<template>
  <el-select v-model="computedValue" class="choose-line-name">
    <div slot="prefix">
      <div v-if="value !== undefined" class="line-icon" v-html="lineNameList.find(item => item.name === value).content"/>
    </div>
    <template v-for="line in lineNameList">
      <el-option :value="line.name">
        <div class="line-icon" v-html="line.content"/>
      </el-option>
    </template>
  </el-select>
</template>
<script>

export default {
  name: "ChooseLineName",
  
  model: {
    prop: 'value',
    event: 'change'
  },
  
  props: {
    value: {}
  },
  
  data() {
    return {
      lineNameList: [
        {
          name: 'curve',
          content: ' <svg xmlns="http://www.w3.org/2000/svg" version="1.1" style="height: 20px"><g fill="none" stroke="black" stroke-width="1"><path d="M0 9 a100,50 0 0,1 85,0"/></g></svg>'
        },
        {
          name: 'polyline',
          content: '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" style="height: 20px"><g fill="none" stroke="black" stroke-width="1"><path d="M0 4 l40 0 l0 12 l40 0"/></g></svg>'
        },
        {
          name: 'line',
          content: '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" style="height: 20px"><g fill="none" stroke="black" stroke-width="1"><path d="M0 9 l85 0"/></g></svg>'
        }
      ]
    }
  },
  
  computed: {
    computedValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('change', val)
      }
    }
  }
}
</script>
<style scoped lang="scss">
.choose-line-name {
  ::v-deep {
    .el-input__prefix {
      display: block;
      height: 26px;
      left: 2px;
      top: 1px;
      right: 32px;
      background-color: white;
      padding-left: 8px;
      cursor: pointer;
     
      div {
        height: 100%;
      }
    }
  }
}

.line-icon {
  display: flex;
  align-items: center;
  height: 100%;
  width: 85px;
}

</style>