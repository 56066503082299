<template>
  <div class="configuration-editor" v-loading="loading">
    <div class="header">
      <ToolBar/>
    </div>
    <div class="editor">
      <div class="left" v-show="lockState == 0">
        <ComponentMenu ref="componentMenu"/>
      </div>
      <div class="center" ref="engine"/>
      <div class="right" v-show="lockState == 0">
        <PageProperty
          v-show="showTabs === 0"
          class="property-tabs"
        />
        <SingleComponentProperty
          v-show="showTabs === 1"
          class="property-tabs"
        />
        <MultiComponentsProperty
          v-show="showTabs === 2"
          class="property-tabs"
        />
      </div>
    </div>
    <ContextMenu
      v-model="showContextMenu"
      :x="x"
      :y="y"
    />
  </div>
</template>
<script>
import Vue from 'vue'
import {ConfigurationEngine, deepClone, registerEcharts} from "@/components/ConfigurationEngine";
import ComponentMenu from "./components/ComponentMenu";
import PageProperty from "./components/PageProperty";
import SingleComponentProperty from "./components/SingleComponentProperty";
import MultiComponentsProperty from "./components/MultiComponentsProperty";
import {detail} from "@/api/page";
import ContextMenu from "./components/ContextMenu";
import ToolBar from "@/components/ConfigurationEditor/components/ToolBar";
import {uploadFile} from "@/api/common";

export default {
  name: "ConfigurationEditor",
  components: {ToolBar, ContextMenu, MultiComponentsProperty, SingleComponentProperty, PageProperty, ComponentMenu},
  
  props: {
    id: {}
  },
  
  data() {
    return {
      loading: false,
      eventBus: new Vue(),
      detail: {},
      showTabs: 0,
      showContextMenu: false,
      x: 0,
      y: 0,
      lockState: 0
    }
  },
  
  watch: {
    id() {
      this.loadData()
    }
  },
  
  mounted() {
    this.engine = new ConfigurationEngine(this.$refs.engine)
    registerEcharts(this.$echarts)
    this.bindEvents()
    this.engine.on('*', this.handleEngineEvent)
    this.engine.store.options.uploadFn = async file => {
      return await uploadFile(file).then(res => res.data)
    }
    this.loadData()
  },
  
  provide() {
    return {
      eventBus: this.eventBus,
      editor: this
    }
  },
  
  beforeDestroy() {
    this.engine.off('*', this.handleEngineEvent)
    this.engine.destroy()
    this.unbindEvents()
    this.eventBus.$destroy()
  },
  
  methods: {
    loadData() {
      if (this.id) {
        this.loading = true
        detail({id: this.id}).then(res => {
          let detail = res.data
          this.detail = detail
          let data = {
            grid: true,
            gridSize: 100,
            rule: true,
            pens: []
          }
          if (detail.v2Data) {
            try {
              data = JSON.parse(detail.v2Data)
            } catch (e) {
            }
          }
          let penMap = {};
          (data.pens || []).forEach(pen => penMap[pen.id] = pen);
          (data.pens || []).forEach(pen => {
            if (pen.children?.length) {
              pen.children = pen.children.filter(id => !!penMap[id])
            }
            if (pen.parentId && !penMap[pen.parentId]) {
              pen.parentId = undefined
            }
          })
          data.locked = 0
          this.lockState = data.locked
          this.engine.open(data)
          this.$nextTick(() => {
            this.engine.canvas.resize()
          })
        }).finally(() => {
          setTimeout(() => {
            this.loading = false
          }, 500)
        })
      }
    },
    
    bindEvents() {
      this.eventBus.$on('pageChange', this.handlePageChange)
      this.eventBus.$on('communicationChange', this.handleCommunicationChange)
      this.eventBus.$on('penChange', this.handlePenChange)
      this.eventBus.$on('penActive', this.handlePenActive)
      this.eventBus.$on('changeLineType', this.handleChangeLineType)
      this.eventBus.$on('layout', this.handleLayout)
      this.eventBus.$on('align', this.handleAlign)
      this.eventBus.$on('align', this.handleAlign)
      this.eventBus.$on('animation', this.handleAnimation)
    },
    
    unbindEvents() {
      this.eventBus.$off('pageChange', this.handlePageChange)
      this.eventBus.$off('communicationChange', this.handleCommunicationChange)
      this.eventBus.$off('penChange', this.handlePenChange)
      this.eventBus.$off('penActive', this.handlePenActive)
      this.eventBus.$off('changeLineType', this.handleChangeLineType)
      this.eventBus.$off('layout', this.handleLayout)
      this.eventBus.$off('align', this.handleAlign)
      this.eventBus.$off('align', this.handleAlign)
      this.eventBus.$off('animation', this.handleAnimation)
    },
    
    handleEngineEvent(event, data) {
      switch (event) {
        case 'opened':
          this.eventBus.$emit('openPage', this.engine.store.data)
          break
        case 'active':
          this.emitActivePens()
          break
        case 'inactive':
          this.emitActivePens()
          break
        case 'add':
        case 'delete':
        case 'redo':
        case 'undo':
        case 'update':
          this.emitActivePens(true)
          break
        case 'resizePens':
          this.emitActivePens()
          break
        case 'rotatePens':
          this.emitActivePens()
          break
        case 'contextmenu':
          let e = data.e
          this.x = e.pageX
          this.y = e.pageY
          this.showContextMenu = true
          this.emitActivePens()
          break
        case 'click':
          this.showContextMenu = false
          break
        case 'scale':
          this.eventBus.$emit('pageOptionChange')
          break
      }
    },
    
    emitActivePens(updateAll = false) {
      setTimeout(() => {
        let activePens = this.engine.store.active.map(item => {
          let pen = deepClone(item)
          let rect;
          if (item.type) {
            let fromAnchor = item.calculative.worldAnchors[0]
            let toAnchor = item.calculative.worldAnchors.slice(-1)[0]
            rect = {
              x: fromAnchor.x,
              y: fromAnchor.y,
              ex: toAnchor.x,
              ey: toAnchor.y
            }
          } else {
            rect = this.engine.getPenRect(item)
          }
          Object.assign(pen, rect)
          return pen
        })
        this.showTabs = activePens.length === 0 ? 0 : activePens.length > 1 ? 2 : 1
        this.eventBus.$emit('active', activePens)
        if (updateAll) {
          this.eventBus.$emit('updatePens', this.engine.store.data.pens)
        }
      }, 10)
    },
    
    handlePageChange(val) {
      let storeData = this.engine.store.data
      Object.assign(storeData, val)
      this.engine.setBackgroundColor(val.background)
      this.engine.setBackgroundImage(val.bkImage)
      this.engine.setGrid(storeData)
      this.engine.setRule(storeData)
      this.engine.render()
    },
    
    handleCommunicationChange(val) {
      let storeData = this.engine.store.data
      Object.assign(storeData, val)
      this.engine.listenSocket()
      this.engine.connectSocket()
    },
    
    handleLayout(e) {
      let activePens = this.engine.store.active || []
      if (activePens.length) {
        this.engine.layout(activePens, e.maxWidth, e.space)
      } else {
        this.engine.layout(undefined, e.maxWidth, e.space)
      }
    },
    
    handlePenActive(id) {
      this.engine.active([this.engine.store.pens[id]], false)
      this.engine.render()
    },
    
    handlePenChange(pen) {
      this.engine.setValue(pen)
    },
    
    handleChangeLineType(pen) {
      let oldPen = this.engine.findOne(pen.id)
      this.engine.updateLineType(oldPen, pen.lineName)
    },
    
    handleAnimation(e) {
      let {id, method} = e
      this.engine[method](id)
    },
    
    handleAlign(e) {
      let {position, isAll} = e
      let pens = this.engine.store.active.map(item => this.engine.findOne(item.id))
      if (isAll) {
        if (position === 'horizontal-between') {
          this.engine.spaceBetween(pens)
        } else if (position === 'vertical-between') {
          this.engine.spaceBetweenColumn(pens)
        } else {
          this.engine.alignNodes(position, pens)
        }
      } else {
        if (position === 'same') {
          this.engine.beSameByFirst(pens)
        } else if (position === 'format') {
          this.engine.formatPainterByFirst(pens)
        } else {
          this.engine.alignNodesByFirst(position, pens)
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
.configuration-editor {
  height: 100%;
  padding-top: 50px;
  overflow: hidden;
  text-align: left;
  
  .header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 50px;
    box-shadow: 0 2px 4px #dad7d7;
    background-color: #ffffff;
    z-index: 2;
    padding: 0 16px;
    display: flex;
    align-items: center;
  }
  
  .editor {
    display: flex;
    height: calc(100vh - 50px);
    overflow-x: hidden;
    
    .left {
      width: 200px;
      height: 100%;
      border-right: 1px solid #ddd;
    }
    
    .center {
      flex: 1;
    }
    
    .right {
      width: 240px;
      height: 100%;
      border-left: 1px solid #ddd;
      
      .property-tabs {
        height: 100%;
        background-color: #ffffff;
        position: relative;
      }
    }
  }
}
</style>