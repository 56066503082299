export const toArrowList = [
  {
    name: '',
    icon: 'c-icon c-line'
  },
  {
    name: 'triangle',
    icon: 'c-icon c-to-triangle'
  },
  {
    name: 'diamond',
    icon: 'c-icon c-to-diamond'
  },
  {
    name: 'circle',
    icon: 'c-icon c-to-circle'
  },
  {
    name: 'lineDown',
    icon: 'c-icon c-to-lineDown'
  },
  {
    name: 'lineUp',
    icon: 'c-icon c-to-lineUp'
  },
  {
    name: 'triangleSolid',
    icon: 'c-icon c-to-triangleSolid'
  },
  {
    name: 'diamondSolid',
    icon: 'c-icon c-to-diamondSolid'
  },
  {
    name: 'circleSolid',
    icon: 'c-icon c-to-circleSolid'
  },
  {
    name: 'line',
    icon: 'c-icon c-to-line'
  }
]

export const fromArrowList =  [
  {
    name: '',
    icon: 'c-icon c-line'
  },
  {
    name: 'triangle',
    icon: 'c-icon c-from-triangle'
  },
  {
    name: 'diamond',
    icon: 'c-icon c-from-diamond'
  },
  {
    name: 'circle',
    icon: 'c-icon c-from-circle'
  },
  {
    name: 'lineDown',
    icon: 'c-icon c-from-lineDown'
  },
  {
    name: 'lineUp',
    icon: 'c-icon c-from-lineUp'
  },
  {
    name: 'triangleSolid',
    icon: 'c-icon c-from-triangleSolid'
  },
  {
    name: 'diamondSolid',
    icon: 'c-icon c-from-diamondSolid'
  },
  {
    name: 'circleSolid',
    icon: 'c-icon c-from-circleSolid'
  },
  {
    name: 'line',
    icon: 'c-icon c-from-line'
  }
]