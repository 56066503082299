<template>
  <el-tabs v-model="activeName" class="page-property">
    <el-tab-pane :label="$lang.configurationEditor.page" name="page">
      <PageConfig/>
    </el-tab-pane>
    <el-tab-pane :label="$lang.configurationEditor.communication" name="communication">
      <CommunicationConfig/>
    </el-tab-pane>
    <el-tab-pane :label="$lang.configurationEditor.layout" name="layout">
      <LayoutConfig/>
    </el-tab-pane>
    <el-tab-pane :label="$lang.configurationEditor.node" name="nodeList">
      <ComponentsConfig/>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import PageConfig from "./PageConfig";
import CommunicationConfig from "./CommunicationConfig";
import LayoutConfig from "./LayoutConfig";
import ComponentsConfig from "../ComponentsConfig";
export default {
  name: "PageProperty",
  components: {ComponentsConfig, LayoutConfig, CommunicationConfig, PageConfig},
  props: {
    data: {},
    pens: []
  },
  
  data() {
    return {
      activeName: 'page'
    }
  }
}
</script>
<style scoped lang="scss">
.page-property {
  
  ::v-deep {
    > .el-tabs__header {
      margin: 0;
      
      .el-tabs__nav {
        .el-tabs__item {
          padding: 0 8px;
        }
      }
    }
    
    > .el-tabs__content {
      height: calc(100% - 40px);
      
      > .el-tab-pane {
        height: 100%;
        
        > .el-scrollbar {
          height: 100%;
          
          > .el-scrollbar__wrap {
            overflow-x: auto;
          }
        }
      }
    }
  }
}
</style>