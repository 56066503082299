import ChooseImage from '../components/ChooseImage'
import EditScript from '../components/EditScript'

export default [
  {
    name: 'color',
    label: 'defaultColor',
    component: 'color-picker',
    props: {
      showAlpha: true
    }
  },
  {
    name: 'penBackground',
    label: 'penBackground',
    component: 'color-picker',
    props: {
      showAlpha: true
    }
  },
  {
    name: 'background',
    label: 'backgroundColor',
    component: 'color-picker',
    props: {
      showAlpha: true
    }
  },
  {
    name: 'bkImage',
    label: 'bkImage',
    component: ChooseImage
  },
  {
    name: 'grid',
    label: 'showGrid',
    component: 'el-switch'
  },
  {
    name: 'gridColor',
    label: 'gridColor',
    component: 'color-picker',
    props: {
      showAlpha: true
    }
  },
  {
    name: 'gridSize',
    label: 'gridSize',
    component: 'el-input-number',
    props: {
      min: 0,
      controlsPosition: 'right'
    }
  },
  {
    name: 'gridRotate',
    label: 'gridRotate',
    component: 'el-input-number',
    props: {
      controlsPosition: 'right'
    }
  },
  {
    name: 'rule',
    label: 'showRule',
    component: 'el-switch'
  },
  {
    name: 'ruleColor',
    label: 'ruleColor',
    component: 'color-picker',
    props: {
      showAlpha: true
    }
  },
  {
    name: 'initJs',
    label: 'initJs',
    component: EditScript
  }
]