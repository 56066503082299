<template>
  <el-select v-model="computedValue" class="choose-from-arrow">
    <div slot="prefix">
      <i v-if="value !== undefined" class="arrow-icon" :class="arrowList.find(item => item.name === value).icon"/>
    </div>
    <template v-for="line in arrowList">
      <el-option :value="line.name">
        <i class="arrow-icon" :class="line.icon"/>
      </el-option>
    </template>
  </el-select>
</template>
<script>
import {fromArrowList} from '../../data/arrow-list'

export default {
  name: "ChooseFromArrow",
  
  model: {
    prop: 'value',
    event: 'change'
  },
  
  props: {
    value: {}
  },
  
  data() {
    return {
      arrowList: fromArrowList
    }
  },
  
  computed: {
    computedValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('change', val)
      }
    }
  }
}
</script>
<style scoped lang="scss">
.choose-from-arrow {
  ::v-deep {
    .el-input__prefix {
      display: block;
      height: 26px;
      left: 2px;
      top: 1px;
      right: 32px;
      background-color: white;
      padding-left: 8px;
      cursor: pointer;
      text-align: left;
     
      div {
        height: 100%;
      }
    }
  }
}

.arrow-icon {
  font-size: 30px;
  color: black;
}
</style>