<template>
  <div class="tool-bar">
    <div>
      <show-space>
<!--        <div class="tool-item" @click="handleBack">-->
<!--          <div class="icon">-->
<!--            <i class="el-icon-back"/>-->
<!--          </div>-->
<!--          <div class="text">返回</div>-->
<!--        </div>-->
        <div class="tool-item" @click="handleSave">
          <div class="icon">
            <i class="el-icon-_save"/>
          </div>
          <div class="text">{{$lang.button.save}}</div>
        </div>
        <div class="tool-item" :class="{disabled: !showTabs}" @click="handleSaveComponent">
          <div class="icon">
            <i class="el-icon-_save"/>
          </div>
          <div class="text">{{$lang.configurationEditor.saveComponentToLib}}</div>
        </div>
      </show-space>
      <SaveComponentDialog
        ref="saveComponent"
        @success="handleSaveComponentSuccess"
      />
    </div>
    <show-space style="margin: 0 32px">
      <div class="tool-item" :class="{active: drawingLine}" @click="toggleDrawingLine">
        <div class="icon">
          <i class="c-icon c-curve"/>
        </div>
        <div class="text">钢笔</div>
      </div>
      <div class="tool-item" :class="{active: pencil}" @click="togglePencil">
        <div class="icon">
          <i class="c-icon c-qianbi"/>
        </div>
        <div class="text">铅笔</div>
      </div>
      <div class="tool-item" :class="{active: showMagnifier}" @click="toggleMagnifier">
        <div class="icon">
          <i class="c-icon c-fangdajing"/>
        </div>
        <div class="text">放大镜</div>
      </div>
      <div class="tool-item" :class="{active: showMap}" @click="toggleMap">
        <div class="icon">
          <i class="c-icon c-ditu"/>
        </div>
        <div class="text">缩略图</div>
      </div>
      <el-dropdown placement="bottom-start" @command="changeArrow($event, true)">
        <div class="tool-item">
          <div class="icon">
            <i :class="fromArrowIconMap[this.fromArrow || '']"/>
          </div>
          <div class="text">起点</div>
        </div>
        <el-dropdown-menu slot="dropdown">
          <template v-for="arrow in fromArrowList">
            <el-dropdown-item :command="arrow.name">
              <i :class="arrow.icon" style="font-size: 28px"/>
            </el-dropdown-item>
          </template>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown placement="bottom-start" @command="changeArrow($event, false)">
        <div class="tool-item">
          <div class="icon">
            <i :class="toArrowIconMap[this.toArrow || '']"/>
          </div>
          <div class="text">终点</div>
        </div>
        <el-dropdown-menu slot="dropdown">
          <template v-for="arrow in toArrowList">
            <el-dropdown-item :command="arrow.name">
              <i :class="arrow.icon" style="font-size: 28px"/>
            </el-dropdown-item>
          </template>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown placement="bottom-start" @command="changeLineType($event)">
        <div class="tool-item">
          <div class="icon">
            <i :class="lineIconMap[drawingLineName || 'curve']"/>
          </div>
          <div class="text">连线</div>
        </div>
        <el-dropdown-menu slot="dropdown">
          <template v-for="line in lineNameList">
            <el-dropdown-item :command="line.name">
              <i :class="line.icon" style="font-size: 28px"/>
            </el-dropdown-item>
          </template>
        </el-dropdown-menu>
      </el-dropdown>
      <div class="tool-item" :class="{active: autoAnchor}" @click="toggleAutoAnchor">
        <div class="icon">
          <i class="c-icon c-maodian"/>
        </div>
        <div class="text">自动锚点</div>
      </div>
      <div class="tool-item" :class="{active: disableAnchor}" @click="toggleDisableAnchor">
        <div class="icon">
          <i class="c-icon c-maodianjiyong"/>
        </div>
        <div class="text">禁用锚点</div>
      </div>
    </show-space>
    <show-space>
      <div v-if="lockState == 0" class="tool-item" @click="handlePreview(1)">
        <div class="icon">
          <i class="el-icon-view"/>
        </div>
        <div class="text">{{$lang.configurationEditor.preview}}</div>
      </div>
      <div v-else class="tool-item" @click="handlePreview(0)">
        <div class="icon">
          <i class="el-icon-_eye-close"/>
        </div>
        <div class="text">{{$lang.configurationEditor.exitPreview}}</div>
      </div>
      <el-popover
        placement="top"
        trigger="hover"
        popper-class="c-tool-popover"
        :visible-arrow="false"
        width="100">
        <el-input-number
          v-model="lineWidth"
          size="mini"
          :precision="0"
          :min="0"
          controls-position="right"
          class="line-width-input"
          @change="changeLineWidth"
        />
        <div class="tool-item" slot="reference">
          <div class="icon">
            <span style="font-size: 14px">{{lineWidth}}</span>
            <i class="c-icon c-triangle-down"/>
          </div>
          <div class="text">线宽</div>
        </div>
      </el-popover>
      <el-popover
        placement="top"
        trigger="hover"
        popper-class="c-tool-popover"
        :visible-arrow="false">
        <show-space class="scale-row">
          <el-slider
            v-model="scale"
            :min="minScale"
            :max="maxScale"
            :step="0.1"
            @change="changeScale"
          />
          <el-button size="mini" @click="fitView">窗口大小</el-button>
          <el-button size="mini" @click="changeScale(1)">重置</el-button>
        </show-space>
        <div class="tool-item" slot="reference">
          <div class="icon">
            <span style="font-size: 14px">{{(scale * 100).toFixed(0)}}%</span>
            <i class="c-icon c-triangle-down"/>
          </div>
          <div class="text">缩放</div>
        </div>
      </el-popover>
    </show-space>
  </div>
</template>
<script>
import {save} from "@/api/page";
import {showSuccessMsg} from "@/utils/func";
import SaveComponentDialog from "@/views/componentManage/buttons/SaveComponentDialog";
import {fromArrowList, toArrowList} from '../../data/arrow-list'

const fromArrowIconMap = {}
fromArrowList.forEach(arrow => fromArrowIconMap[arrow.name] = arrow.icon)

const toArrowIconMap = {}
toArrowList.forEach(arrow => toArrowIconMap[arrow.name] = arrow.icon)

const lineNameList = [
  {
    name: 'curve',
    icon: 'c-icon c-curve2'
  },
  {
    name: 'polyline',
    icon: 'c-icon c-polyline'
  },
  {
    name: 'line',
    icon: 'c-icon c-line'
  },
  {
    name: 'mind',
    icon: 'c-icon c-mind'
  }
]
const lineIconMap = {}
lineNameList.forEach(line => lineIconMap[line.name] = line.icon)

export default {
  name: "ToolBar",
  components: {SaveComponentDialog},
  inject: ['eventBus', 'editor'],
  
  data() {
    return {
      drawingLine: false,
      pencil: false,
      showMagnifier: false,
      showMap: false,
      fromArrow: '',
      toArrow: '',
      drawingLineName: '',
      lineWidth: 1,
      scale: 1,
      autoAnchor: false,
      disableAnchor: false,
      fromArrowList: fromArrowList,
      toArrowList: toArrowList,
      fromArrowIconMap: fromArrowIconMap,
      toArrowIconMap: toArrowIconMap,
      lineNameList: lineNameList,
      lineIconMap: lineIconMap,
      minScale: 0.1,
      maxScale: 10
    }
  },
  
  computed: {
    showTabs() {
      return this.editor.showTabs
    },
    lockState: {
      get() {
        return this.editor.lockState
      },
      set(val) {
        this.editor.lockState = val
      }
    },
  },
  
  created() {
    document.addEventListener('keydown', this.onKeyDown);
    this.eventBus.$on('openPage', pageData => {
      this.loadOptions()
    })
    this.eventBus.$on('pageOptionChange', pageData => {
      this.loadOptions()
    })
    Object.defineProperty(this, 'engine', {
      get: () => {
        return this.editor.engine
      }
    })
  },
  
  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeyDown)
  },
  
  methods: {
    onKeyDown(e) {
      switch (e.key) {
        case 'v':
        case 'V':
        case 'b':
        case 'B':
        case 'Enter':
        case 'Escape':
          setTimeout(() => {
            this.loadOptions()
          }, 200)
      }
    },
    
    loadOptions() {
      this.drawingLine = !!this.engine.canvas.drawingLineName
      this.pencil = this.engine.canvas.pencil
      this.showMagnifier = this.engine.canvas.magnifierCanvas.magnifier
      this.showMap = this.engine.map?.isShow === true
      this.fromArrow = this.engine.store.data.fromArrow
      this.toArrow = this.engine.store.data.toArrow
      this.drawingLineName = this.engine.store.options.drawingLineName
      this.autoAnchor = this.engine.store.options.autoAnchor
      this.disableAnchor = this.engine.store.options.disableAnchor
      this.lineWidth = this.engine.store.data.lineWidth || 1
      this.scale = this.engine.store.data.scale
      this.minScale = this.engine.store.options.minScale
      this.maxScale = this.engine.store.options.maxScale
    },
  
    handleBack() {
      if (this.$router) {
        this.$router.back()
        setTimeout(() => {
          if (window.location.hash.indexOf("#/edit/v2") == 0) {
            window.location.reload()
          }
        }, 1000)
      } else {
        history.back()
      }
    },
    
    handlePreview(val) {
      this.lockState = val
      this.$nextTick(() => {
        this.engine.canvas.resize()
        this.engine.lock(val)
      })
    },
    
    handleSave() {
      this.editor.loading = true
      save({
        ...this.editor.detail,
        v2Data: JSON.stringify(this.engine.data())
      }).then(() => {
        showSuccessMsg(this.$lang.message.title, this.$lang.message.saveSuccess)
      }).finally(() => {
        setTimeout(() => {
          this.editor.loading = false
        }, 500)
      })
    },
    
    handleSaveComponent() {
      let data = JSON.stringify(this.engine.toComponent(this.engine.store.active))
      this.$refs.saveComponent.start(data)
    },
    
    handleSaveComponentSuccess() {
      this.editor.$refs.componentMenu.getComponentsData()
    },
    
    toggleDrawingLine() {
      this.drawingLine = !this.drawingLine
      if (this.drawingLine) {
        this.engine.canvas.onkeydown({key: 'V', target: {tagName: 'div'}})
      } else {
        this.engine.canvas.onkeydown({key: 'Escape', target: {tagName: 'div'}})
      }
    },
    
    togglePencil() {
      this.pencil = !this.pencil
      if (this.pencil) {
        this.engine.canvas.onkeydown({key: 'B', target: {tagName: 'div'}})
      } else {
        this.engine.canvas.onkeydown({key: 'Escape', target: {tagName: 'div'}})
      }
    },
  
    toggleMagnifier() {
      this.showMagnifier = !this.showMagnifier
      this.engine.toggleMagnifier()
    },
  
    toggleMap() {
      this.showMap = !this.showMap
      if (this.showMap) {
        this.engine.showMap()
      } else {
        this.engine.hideMap()
      }
    },
    
    changeArrow(name, fromOrTo) {
      let options = {}
      if (fromOrTo) {
        this.fromArrow = name
        options.fromArrow = name
      } else {
        this.toArrow = name
        options.toArrow = name
      }
      this.engine.setOptions(options)
      this.engine.store.data = Object.assign(this.engine.store.data, options)
      let ids = this.engine.store.active.map(item => item.id)
      if (ids.length) {
        this.engine.setValueBatch(options, ids, {history: false})
      }
    },
  
    changeLineType(name) {
      this.drawingLineName = name
      this.engine.store.options.drawingLineName = name
      this.engine.store.active.forEach(pen => {
        this.engine.updateLineType(pen, name)
      })
    },
    
    toggleAutoAnchor() {
      this.autoAnchor = !this.autoAnchor
      this.engine.store.options.autoAnchor = this.autoAnchor
    },
  
    toggleDisableAnchor() {
      this.disableAnchor = !this.disableAnchor
      this.engine.store.options.disableAnchor = this.disableAnchor
    },
    
    changeLineWidth(val) {
      this.engine.store.data.lineWidth = val
      let ids = this.engine.store.active.filter(item => item.type === 1).map(item => item.id)
      if (ids.length) {
        this.engine.setValueBatch({lineWidth: val}, ids, {history: false})
      }
    },
    
    changeScale(val) {
      this.scale = val
      this.engine.scale(val)
    },
    
    fitView() {
      this.engine.fitView(true, 10)
      this.scale = this.engine.store.data.scale
    }
  }
}
</script>
<style scoped lang="scss">
.tool-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  
  .tool-item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    padding: 2px 8px;
    
    &:hover {
      background-color: mix(#409EFF, #FFFFFF, 10%);
      color: #409EFF;
    }
    
    &.active {
      color: #409EFF;
      font-weight: bold;
    }
    
    &.disabled {
      color: #999999;
      cursor: not-allowed;
      pointer-events: none;
    }
    
    .icon {
      font-size: 18px;
      height: 24px;
      position: relative;
      display: flex;
      align-items: center;
    }
    
    .text {
      height: 18px;
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
    }
  }
}

.line-width-input {
  width: 100%;
  
  ::v-deep {
    .el-input__inner {
      text-align: left;
      padding-left: 8px;
    }
  }
}

.scale-row {
  display: flex;
  
  ::v-deep {
    .el-slider {
      margin: 0 5px;
      width: 200px;
      display: flex;
    
      .el-slider__runway {
        margin: 11px 0;
      }
    }
  }
}
</style>
<style lang="scss">
.c-tool-popover {
  transform: translateY(-8px);
  min-width: unset !important;
}
</style>