<template>
  <div>
    <el-button size="mini" style="border-radius: 0!important;padding: 5px 8px;" @click="handleEdit">···</el-button>
    <el-dialog
      :visible.sync="showDialog"
      width="900px"
      append-to-body
      custom-class="edit-script-dialog"
      destroy-on-close
      :title="title">
      <div ref="code" style="height: 400px;"/>
      <show-space slot="footer">
        <el-button size="mini" @click="showDialog = false">{{$lang.button.cancel}}</el-button>
        <el-button size="mini" type="primary" @click="handleConfirm">{{$lang.button.confirm}}</el-button>
      </show-space>
    </el-dialog>
  </div>
</template>
<script>
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api.js';

export default {
  name: "EditScript",
  
  model: {
    prop: 'value',
    event: 'change'
  },
  
  props: {
    value: String,
    language: {
      type: String,
      default: 'JavaScript'
    },
    title: {
      type: String,
      default: 'JavaScript'
    }
  },
  
  data() {
    return {
      showDialog: false
    }
  },
  
  watch: {
    showDialog(val) {
      if (val) {
        this.$nextTick(() => {
          this.editor = monaco.editor.create(this.$refs.code, {
            value: this.value,      // 编辑器内容
            theme: 'vs',  // 主题
            lineNumbers: 'on',   // 行号
            wordWrap: 'off',     // 自动换行
            readOnly: false,     // 是否只读
            minimap: {           // 缩略图
              enabled: false
            },
            semanticHighlighting: {
              enabled: true
            },
            language: this.language
          })
        })
      } else {
        this.editor?.dispose()
      }
    }
  },
  
  methods: {
    handleEdit() {
      this.showDialog = true
    },
    
    handleConfirm() {
      this.$emit('change', this.editor.getValue())
      this.showDialog = false
    }
  }
}
</script>
<style lang="scss">
.edit-script-dialog {
  .el-dialog__body {
    padding: 0 !important;
  }
}
</style>