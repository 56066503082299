<template>
  <div v-if="!value">
    <el-input
      v-if="inputting"
      ref="input"
      v-model="inputUrl"
      :placeholder="$lang.configurationEditor.pleaseInputUrl"
      @blur="handleBlur"
    />
    <template v-else>
      <el-button size="mini" type="text" @click="handleUpload" :loading="uploading">{{$lang.configurationEditor.uploadPic}}</el-button>
      <el-button size="mini" type="text" @click="handleInput">{{$lang.configurationEditor.onlinePic}}</el-button>
    </template>
  </div>
  <div v-else class="choose-image">
    <img :alt="value" :src="value" class="image"/>
    <div class="delete" @click="$emit('change', '')"/>
  </div>
</template>
<script>
import {chooseFile} from "@/utils/func";
import {uploadFile} from "@/api/common";

export default {
  name: "ChooseImage",
  
  model: {
    prop: 'value',
    event: 'change'
  },
  
  props: {
    value: String
  },
  
  data() {
    return {
      uploading: false,
      inputting: false,
      inputUrl: ''
    }
  },
  
  methods: {
    handleUpload() {
      chooseFile(file => {
        this.uploading = true
        uploadFile(file).then(res => {
          this.$emit('change', res.data)
        }).finally(() => {
          setTimeout(() => {
            this.uploading = false
          }, 200)
        })
      }, '.jpg,.png,.gif,.svg')
    },
    handleInput() {
      this.inputting = true
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    },
    handleBlur() {
      this.inputting = false
      this.$emit('change', this.inputUrl)
    }
  }
}
</script>
<style scoped lang="scss">
.choose-image {
  display: flex;
  width: 100px;
  height: 100px;
  justify-content: center;
  align-items: center;
  border: 1px solid #eeeeee;
  position: relative;
  
  .image {
    max-width: 100%;
    max-height: 100%;
  }
  
  .delete {
    display: none;
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: red;
    right: -8px;
    top: -8px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 999999;
  
    &:after,
    &:before {
      content: "";
      display: block;
      width: 10px;
      height: 2px;
      background-color: white;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      transform: rotate(45deg);
    }
    
    &:after {
      transform: rotate(135deg);
    }
  }
  
  &:hover {
    .delete {
      display: block;
    }
  }
}
</style>