import ChooseLineStyle from "@/components/ConfigurationEditor/components/ChooseLineStyle";
import ChooseIcon from "@/components/ConfigurationEditor/components/ChooseIcon";
import fontFamilyList from "@/components/ConfigurationEditor/data/fontFamilyList";

export default [
  {
    name: 'duration',
    label: 'duration',
    component: 'el-input-number',
    props: {
      min: 0,
      controlsPosition: 'right'
    }
  },
  {
    name: 'x',
    label: 'offsetX',
    component: 'el-input-number',
    props: {
      controlsPosition: 'right'
    }
  },
  {
    name: 'y',
    label: 'offsetY',
    component: 'el-input-number',
    props: {
      controlsPosition: 'right'
    }
  },
  {
    name: 'scale',
    label: 'scale',
    component: 'el-input-number',
    props: {
      min: 0,
      controlsPosition: 'right'
    }
  },
  {
    name: 'borderRadius',
    label: 'borderRadius',
    component: 'el-input-number',
    props: {
      placeholder: 'borderRadiusPlaceholder',
      controlsPosition: 'right'
    }
  },
  {
    name: 'rotate',
    label: 'rotate',
    component: 'el-input-number',
    props: {
      controlsPosition: 'right'
    }
  },
  {
    name: 'progress',
    label: 'progress',
    component: 'el-input-number',
    props: {
      step: 0.1,
      min: 0,
      max: 1,
      controlsPosition: 'right'
    }
  },
  {
    name: 'progressColor',
    label: 'progressColor',
    component: 'color-picker',
    props: {
      showAlpha: true
    }
  },
  {
    name: 'dash',
    label: 'lineStyle',
    component: ChooseLineStyle,
    on: {
      changeDash(ctx, frame, value) {
        ctx.$set(frame, 'lineDash', value)
      }
    }
  },
  {
    name: 'lineWidth',
    label: 'lineWidth',
    component: 'el-input-number',
    props: {
      min: 0,
      controlsPosition: 'right'
    }
  },
  {
    name: 'lineDashOffset',
    label: 'lineOffset',
    component: 'el-input-number',
    props: {
      controlsPosition: 'right'
    }
  },
  {
    name: 'globalAlpha',
    label: 'alpha',
    component: 'el-input-number',
    props: {
      step: 0.1,
      min: 0,
      max: 1,
      placeholder: 'alphaPlaceholder',
      controlsPosition: 'right'
    }
  },
  {
    name: 'visible',
    label: 'show',
    component: 'el-switch'
  },
  {
    name: 'strokeType',
    label: 'lineGradient',
    component: 'el-select',
    options: [
      {value: 0, label: 'none'},
      {value: 1, label: 'linearGradient'}
    ]
  },
  {
    name: 'color',
    label: 'color',
    component: 'color-picker',
    isShow(ctx, frame) {
      return frame.strokeType !== 1
    },
    props: {
      showAlpha: true
    }
  },
  {
    name: 'lineGradientFromColor',
    label: 'startColor',
    component: 'color-picker',
    isShow(ctx, frame) {
      return frame.strokeType === 1
    },
    props: {
      showAlpha: true
    }
  },
  {
    name: 'lineGradientToColor',
    label: 'endColor',
    component: 'color-picker',
    isShow(ctx, frame) {
      return frame.strokeType === 1
    },
    props: {
      showAlpha: true
    }
  },
  {
    name: 'lineGradientAngle',
    label: 'gradientAngle',
    component: 'el-input-number',
    isShow(ctx, frame) {
      return frame.strokeType === 1
    },
    props: {
      controlsPosition: 'right'
    }
  },
  {
    name: 'bkType',
    label: 'background',
    component: 'el-select',
    options: [
      {value: 0, label: 'pureColorBackground'},
      {value: 1, label: 'linearGradient'},
      {value: 2, label: 'radialGradient'}
    ]
  },
  {
    name: 'background',
    label: 'backgroundColor',
    component: 'color-picker',
    isShow(ctx, frame) {
      return frame.bkType !== 1 && frame.bkType !== 2
    },
    props: {
      showAlpha: true
    }
  },
  {
    name: 'gradientFromColor',
    label: 'startColor',
    component: 'color-picker',
    isShow(ctx, frame) {
      return frame.bkType === 1 || frame.bkType === 2
    },
    props: {
      showAlpha: true
    }
  },
  {
    name: 'gradientToColor',
    label: 'endColor',
    component: 'color-picker',
    isShow(ctx, frame) {
      return frame.bkType === 1 || frame.bkType === 2
    },
    props: {
      showAlpha: true
    }
  },
  {
    name: 'gradientAngle',
    label: 'gradientAngle',
    component: 'el-input-number',
    isShow(ctx, frame) {
      return frame.bkType === 1
    },
    props: {
      controlsPosition: 'right'
    }
  },
  {
    name: 'gradientRadius',
    label: 'gradientRadius',
    component: 'el-input-number',
    isShow(ctx, frame) {
      return frame.bkType === 2
    },
    props: {
      controlsPosition: 'right',
      min: 0,
      max: 1,
      step: 0.01
    }
  },
  {
    name: 'shadowColor',
    label: 'shadowColor',
    component: 'color-picker',
    props: {
      showAlpha: true
    }
  },
  {
    name: 'shadowBlur',
    label: 'shadowBlur',
    component: 'el-input-number',
    props: {
      controlsPosition: 'right',
      min: 0
    }
  },
  {
    name: 'shadowOffsetX',
    label: 'shadowOffsetX',
    component: 'el-input-number',
    props: {
      controlsPosition: 'right'
    }
  },
  {
    name: 'shadowOffsetY',
    label: 'shadowOffsetY',
    component: 'el-input-number',
    props: {
      controlsPosition: 'right'
    }
  },
  {
    name: 'textHasShadow',
    label: 'textShadow',
    component: 'el-switch'
  },
  {
    name: 'fontFamily',
    label: 'font',
    component: 'el-autocomplete',
    props: {
      fetchSuggestions(queryString, callback) {
        callback(fontFamilyList.map(item => ({value: item})))
      }
    }
  },
  {
    name: 'fontSize',
    label: 'fontSize',
    component: 'el-input-number',
    props: {
      controlsPosition: 'right',
      min: 0
    }
  },
  {
    name: 'textColor',
    label: 'textColor',
    component: 'color-picker',
    props: {
      showAlpha: true
    }
  },
  {
    name: 'hoverTextColor',
    label: 'hoverTextColor',
    component: 'color-picker',
    props: {
      showAlpha: true
    }
  },
  {
    name: 'activeTextColor',
    label: 'activeTextColor',
    component: 'color-picker',
    props: {
      showAlpha: true
    }
  },
  {
    name: 'textBackground',
    label: 'textBackgroundColor',
    component: 'color-picker',
    props: {
      showAlpha: true
    }
  },
  {
    name: 'fontStyle',
    label: 'italic',
    component: 'el-select',
    options: [
      {value: 'normal', label: 'normal'},
      {value: 'italic', label: 'italic'}
    ]
  },
  {
    name: 'fontWeight',
    label: 'bold',
    component: 'el-select',
    options: [
      {value: 'normal', label: 'normal'},
      {value: 'bold', label: 'bold'}
    ]
  },
  {
    name: 'textAlign',
    label: 'horizontalAlign',
    component: 'el-select',
    options: [
      {value: 'left', label: 'alignLeft'},
      {value: 'center', label: 'center'},
      {value: 'right', label: 'alignRight'}
    ]
  },
  {
    name: 'textBaseline',
    label: 'verticalAlign',
    component: 'el-select',
    options: [
      {value: 'top', label: 'alignTop'},
      {value: 'middle', label: 'center'},
      {value: 'bottom', label: 'alignBottom'}
    ]
  },
  {
    name: 'lineHeight',
    label: 'lineHeight',
    component: 'el-input-number',
    props: {
      controlsPosition: 'right',
      min: 0
    }
  },
  {
    name: 'whiteSpace',
    label: 'textWrap',
    component: 'el-select',
    options: [
      {value: '', label: 'default'},
      {value: 'nowrap', label: 'noWrap'},
      {value: 'pre-line', label: 'preLine'},
      {value: 'break-all', label: 'breakAll'}
    ]
  },
  {
    name: 'textWidth',
    label: 'textWidth',
    component: 'el-input-number',
    props: {
      controlsPosition: 'right',
      min: 0
    }
  },
  {
    name: 'textHeight',
    label: 'textHeight',
    component: 'el-input-number',
    props: {
      controlsPosition: 'right',
      min: 0
    }
  },
    {
      name: 'textLeft',
      label: 'offsetHorizontal',
      component: 'el-input-number',
      props: {
        controlsPosition: 'right'
      }
    },
    {
      name: 'textTop',
      label: 'offsetVertical',
      component: 'el-input-number',
      props: {
        controlsPosition: 'right'
      }
    },
  {
    name: 'ellipsis',
    label: 'ellipsis',
    component: 'el-switch'
  },
  {
    name: 'text',
    label: 'textContent',
    component: 'el-input',
    props: {
      type: 'textarea',
      autosize: {
        minRows: 1,
        maxRows: 10
      }
    }
  },
  {
    name: 'icon',
    label: 'icon',
    component: ChooseIcon,
    on: {
      change(ctx, frame, value) {
        if (value) {
          ctx.$set(frame, 'iconFamily', 'xzd-icon')
        } else {
          ctx.$set(frame, 'iconFamily', undefined)
        }
      }
    }
  },
  {
    name: 'iconColor',
    label: 'iconColor',
    component: 'color-picker',
    props: {
      showAlpha: true
    }
  },
  {
    name: 'iconSize',
    label: 'iconSize',
    component: 'el-input-number',
    props: {
      controlsPosition: 'right',
      min: 0
    }
  },
  {
    name: 'iconRotate',
    label: 'iconRotate',
    component: 'el-input-number',
    props: {
      controlsPosition: 'right'
    }
  },
  {
    name: 'iconAlign',
    label: 'alignWay',
    component: 'el-select',
    options: [
      {value: 'center', label: 'center'},
      {value: 'top', label: 'top'},
      {value: 'bottom', label: 'bottom'},
      {value: 'left', label: 'left'},
      {value: 'right', label: 'right'},
      {value: 'left-top', label: 'leftTop'},
      {value: 'right-top', label: 'rightTop'},
      {value: 'left-bottom', label: 'leftBottom'},
      {value: 'right-bottom', label: 'rightBottom'}
    ]
  },
  {
    name: 'iconLeft',
    label: 'offsetHorizontal',
    component: 'el-input-number',
    props: {
      controlsPosition: 'right'
    }
  },
  {
    name: 'iconTop',
    label: 'offsetVertical',
    component: 'el-input-number',
    props: {
      controlsPosition: 'right'
    }
  }
]
