<template>
    <el-input
      ref="input"
      v-model="url"
      :placeholder="$lang.configurationEditor.pleaseInputUrl">
      <el-button icon="el-icon-upload" slot="append" @click="handleUpload" :loading="uploading"/>
    </el-input>
</template>
<script>
import {chooseFile} from "@/utils/func";
import {uploadFile} from "@/api/common";

export default {
  name: "ChooseVideo",
  
  model: {
    prop: 'value',
    event: 'change'
  },
  
  props: {
    value: String
  },
  
  data() {
    return {
      uploading: false,
      inputUrl: ''
    }
  },
  
  computed: {
    url: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('change', val)
      }
    }
  },
  
  methods: {
    handleUpload() {
      chooseFile(file => {
        this.uploading = true
        uploadFile(file).then(res => {
          this.url = res.data
        }).finally(() => {
          setTimeout(() => {
            this.uploading = false
          }, 200)
        })
      }, '.mp4,.avi,.rmvb')
    }
  }
}
</script>
<style scoped lang="scss">
.choose-image {
  display: flex;
  width: 100px;
  height: 100px;
  justify-content: center;
  align-items: center;
  border: 1px solid #eeeeee;
  position: relative;
  
  .image {
    max-width: 100%;
    max-height: 100%;
  }
  
  .delete {
    display: none;
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: red;
    right: -8px;
    top: -8px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 999999;
  
    &:after,
    &:before {
      content: "";
      display: block;
      width: 10px;
      height: 2px;
      background-color: white;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      transform: rotate(45deg);
    }
    
    &:after {
      transform: rotate(135deg);
    }
  }
  
  &:hover {
    .delete {
      display: block;
    }
  }
}
</style>