<template>
  <div class="choose-icon">
    <i v-if="!value" class="el-icon-edit" @click="showDialog = true"/>
    <show-space v-else>
      <i class="icon-btn x-icon" @click="showDialog = true">{{value}}</i>
      <i class="icon-btn c-icon c-shanchu" @click="$emit('change', '')"/>
    </show-space>
    <el-dialog
      width="904px"
      :visible.sync="showDialog"
      append-to-body
      :title="$lang.configurationEditor.chooseIcon">
      <div class="icon-list">
        <template v-for="icon in iconList">
          <i class="x-icon" :class="'x-icon-' + icon" @click="handleChooseIcon"/>
        </template>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {getStyle} from "@/utils/dom";

const iconList = [
  "cube",
  "up-down",
  "website",
  "clock",
  "camera",
  "flow",
  "dashboard1",
  "github",
  "upload",
  "download",
  "analytics",
  "stop",
  "stop1",
  "kefu",
  "exit1",
  "enter",
  "exit",
  "share",
  "wechat",
  "pay6",
  "pay3",
  "weibo",
  "message",
  "app",
  "shoppingcart",
  "people",
  "people2geren",
  "people4geren",
  "jiankong",
  "cpu",
  "iot",
  "iot1",
  "iot2",
  "warning",
  "error",
  "success",
  "document",
  "folder",
  "list",
  "kaiguan",
  "search",
  "remote-control",
  "browser",
  "185055paintingpalletstreamline",
  "house",
  "map",
  "sound",
  "link",
  "umbrella",
  "dashboard",
  "settings",
  "sync",
  "data-stream",
  "streaming",
  "record",
  "streamSQL",
  "locked",
  "unlocked",
  "api",
  "email",
  "apiassembly",
  "api1",
  "api2",
  "nodejs",
  "java",
  "safe",
  "html",
  "python",
  "docker",
  "golang",
  "ks",
  "cloud-code",
  "rocketmq",
  "kafka",
  "fuwuqi",
  "rabbitmq",
  "cassandra",
  "MongoDB",
  "hbase",
  "redis",
  "sql",
  "mysql",
  "pgsql",
  "parallel",
  "db",
  "data1",
  "data3",
  "data2",
  "data",
  "bub",
  "zuoji",
  "firewall",
  "cloud-firewall",
  "cloud-server",
  "earch",
  "printer",
  "satelite",
  "satelite2",
  "router",
  "router2",
  "antenna",
  "antenna2",
  "antenna3",
  "building",
  "office",
  "ipad",
  "wifi",
  "network1",
  "network",
  "home",
  "cloud",
  "mobile",
  "pc",
]

export default {
  name: "ChooseIcon",
  
  model: {
    prop: 'value',
    event: 'change'
  },
  
  props: {
    value: String
  },
  
  data() {
    return {
      showDialog: false,
      iconList: iconList,
    }
  },
  
  methods: {
    handleChooseIcon(e) {
      let value = JSON.parse(getStyle(e.target, 'content', ':before'))
      this.$emit('change', value)
      this.showDialog = false
    }
  }
}
</script>
<style scoped lang="scss">
.choose-icon {
  display: inline-flex;
  align-items: center;
  line-height: 28px;
  
  .icon-btn {
    font-size: 16px !important;
    cursor: pointer;
    
    &:hover {
      color: #409EFF;
    }
  }
}

.icon-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  
  .x-icon {
    font-size: 32px !important;
    width: 48px;
    height: 48px;
    text-align: center;
    line-height: 48px;
    cursor: pointer;
    
    &:nth-child(18n) {
      margin-right: 0;
    }
    
    &:hover {
      background-color: #409EFF;
      color: white;
    }
  }
}
</style>