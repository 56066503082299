<template>
  <el-scrollbar>
    <el-form size="mini" class="config-form" label-width="100px" label-position="left">
      <el-form-item label="ID" style="margin-top: 8px">
        <el-input v-model="id" @change="handleIdChange"/>
      </el-form-item>
      <el-form-item :label="$lang.configurationEditor.name">
        <el-input v-model="data.description"/>
      </el-form-item>
      <el-collapse v-model="activeNames">
        <el-collapse-item :title="$lang.configurationEditor.tag" name="tags">
          <div class="tag-list">
            <template v-for="(tag, index) in data.tags">
              <el-tag closable size="mini" @close="data.tags.splice(index, 1)">{{tag}}</el-tag>
            </template>
          </div>
          <div style="padding: 0 16px">
            <el-input
              v-model="currentTag"
              size="mini"
              @keypress.native.enter="handleAddTag"
              :placeholder="$lang.configurationEditor.tagPlaceholder"
            />
          </div>
        </el-collapse-item>
        <el-collapse-item :title="$lang.configurationEditor.data" name="data">
          <el-form size="mini" class="data-form" label-width="100px" label-position="left">
            <template v-for="(item, index) in data.form || []">
              <div class="data-form-row">
                <el-form-item :label="item.name">
                  <el-input
                    v-if="item.type === 'text'"
                    v-model="data[item.key]"
                    :placeholder="item.placeholder"
                  />
                  <el-input-number
                    v-if="item.type === 'number'"
                    v-model="data[item.key]"
                    :min="item.min"
                    :max="item.max"
                    :step="item.step"
                    :precision="item.precision"
                    :placeholder="item.placeholder"
                    controls-position="right"
                  />
                  <color-picker
                    v-if="item.type === 'color'"
                    v-model="data[item.key]"
                    :placeholder="item.placeholder"
                    show-alpha
                  />
                  <el-input
                    v-if="item.type === 'textarea'"
                    type="textarea"
                    v-model="data[item.key]"
                    :placeholder="item.placeholder"
                    :autosize="{minRows: 2}"
                  />
                  <el-select
                    v-if="item.type === 'select'"
                    v-model="data[item.key]"
                    :placeholder="item.placeholder">
                    <template v-for="option in item.options">
                      <el-option :value="option.value" :label="option.label"/>
                    </template>
                  </el-select>
                  <el-switch
                    v-if="item.type === 'switch'"
                    v-model="data[item.key]"
                    :placeholder="item.placeholder"
                  />
                  <EditScript
                    v-if="item.type === 'json'"
                    :value="JSON.stringify(data[item.key], null, 4)"
                    @change="$set(data, item.key, JSON.parse($event))"
                    :language="item.language"
                    :title="item.title"
                  />
                  <el-slider
                    v-if="item.type === 'slider'"
                    v-model="data[item.key]"
                    :min="item.min"
                    :max="item.max"
                    :step="item.step"
                    :placeholder="item.placeholder"
                  />
                </el-form-item>
                <ShowSpace>
                  <i class="el-icon-edit" @click="handleEditForm(index)"/>
                  <el-popconfirm :title="$lang.confirm.delete" @confirm="handleDeleteForm(index)">
                    <i slot="reference" class="c-icon c-shanchu"/>
                  </el-popconfirm>
                </ShowSpace>
              </div>
            </template>
            <div style="padding: 0 16px">
              <el-button size="mini" style="width: 100%" @click="handleAddForm">{{$lang.configurationEditor.addData}}</el-button>
            </div>
          </el-form>
        </el-collapse-item>
      </el-collapse>
    </el-form>
    <el-dialog
      :title="editIndex === -1 ? $lang.configurationEditor.addData : $lang.configurationEditor.editData"
      :visible.sync="showFormDialog"
      append-to-body
      destroy-on-close
      width="500px">
      <el-form
        :model="form"
        :rules="formRule"
        ref="form"
        size="mini"
        label-width="80px"
        label-position="left">
        <el-form-item :label="$lang.configurationEditor.displayName" prop="name">
          <el-input v-model="form.name"/>
        </el-form-item>
        <el-form-item :label="$lang.configurationEditor.propName" prop="key">
          <el-input v-model="form.key"/>
        </el-form-item>
        <el-form-item :label="$lang.configurationEditor.type" prop="type">
          <el-select v-model="form.type" class="custom-input">
            <el-option value="text" :label="$lang.configurationEditor.text"/>
            <el-option value="number" :label="$lang.configurationEditor.number"/>
            <el-option value="color" :label="$lang.configurationEditor.color"/>
            <el-option value="textarea" :label="$lang.configurationEditor.multiText"/>
            <el-option value="select" :label="$lang.configurationEditor.dropDown"/>
            <el-option value="switch" :label="$lang.configurationEditor.switch"/>
            <el-option value="json" label="Json"/>
            <el-option value="slider" :label="$lang.configurationEditor.slider"/>
          </el-select>
        </el-form-item>
        <el-form-item :label="$lang.configurationEditor.placeholder">
          <el-input v-model="form.placeholder"/>
        </el-form-item>
        <template v-if="['number', 'slider'].includes(form.type)">
          <el-form-item :label="$lang.configurationEditor.minValue">
            <el-input-number
              v-model="form.min"
              controls-position="right"
              class="custom-input"
            />
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.maxValue">
            <el-input-number
              v-model="form.max"
              controls-position="right"
              class="custom-input"
            />
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.step">
            <el-input-number
              v-model="form.step"
              controls-position="right"
              :min="0"
              class="custom-input"
            />
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.precision" v-if="form.type === 'number'">
            <el-input-number
              v-model="form.precision"
              controls-position="right"
              :min="0"
              :step="1"
              :precision="0"
              class="custom-input"
            />
          </el-form-item>
        </template>
        <el-form-item v-if="form.type == 'select'" :label="$lang.configurationEditor.optionList" prop="options">
          <el-table
            :data="form.options || []"
            border
            size="mini">
            <el-table-column :label="$lang.configurationEditor.optionValue">
              <template slot-scope="{row}">
                <el-input v-model="row.value"/>
              </template>
            </el-table-column>
            <el-table-column :label="$lang.configurationEditor.optionName">
              <template slot-scope="{row}">
                <el-input v-model="row.label"/>
              </template>
            </el-table-column>
            <el-table-column width="40px" align="center">
              <template slot="header">
                <i class="data-form-row-icon c-icon c-roundadd" @click="handleAddOption" style="cursor: pointer"/>
              </template>
              <template slot-scope="{$index}">
                <i slot="reference" class="data-form-row-icon c-icon c-shanchu" @click="handleDeleteOption($index)"/>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item style="margin-bottom: 0;margin-top: -30px;height: 0"></el-form-item>
      </el-form>
      <ShowSpace slot="footer">
        <el-button size="mini" @click="showFormDialog = false">{{$lang.button.cancel}}</el-button>
        <el-button size="mini" type="primary" @click="handleSaveFormConfirm">{{$lang.button.confirm}}</el-button>
      </ShowSpace>
    </el-dialog>
  </el-scrollbar>
</template>
<script>
import EditScript from "../../components/EditScript";
import EditAnimationFrames from "../../components/EditAnimationFrames";
import {deepClone} from "@/components/ConfigurationEngine";

const propertyList = [
  'id',
  'description',
  'tags',
  'form'
]

export default {
  name: "FormConfig",
  components: {EditAnimationFrames, EditScript},
  
  data() {
    return {
      activeNames: ['tags', 'data'],
      id: null,
      data: {},
      currentTag: '',
      form: this.getInitFormData(),
      showFormDialog: false,
      editIndex: -1
    }
  },
  
  computed: {
    formRule() {
      return {
        name: [{required: true, message: this.$lang.configurationEditor.displayNamePlaceholder}],
        key: [{required: true, message: this.$lang.configurationEditor.propNamePlaceholder}],
        type: [{required: true, message: this.$lang.configurationEditor.typePlaceholder}]
      }
    }
  },
  
  watch: {
    data: {
      handler(val) {
        if (this.isEdit && val.id) {
          this.eventBus.$emit('penChange', JSON.parse(JSON.stringify(val)))
        }
      },
      deep: true
    }
  },
  
  inject: ['eventBus', 'editor'],
  
  created() {
    this.eventBus.$on('active', pens => {
      if (pens.length === 1) {
        this.isEdit = false
        let pen = pens[0]
        let data = {}
        propertyList.forEach(key => {
          if (pen[key] !== undefined) {
            data[key] = pen[key]
          }
        })
        this.handleGetPropsFromForm(data.form, data, pen)
        this.data = data
        this.id = data.id
        this.$nextTick(() => {
          this.isEdit = true
        })
      }
    })
  },
  
  methods: {
    getInitFormData() {
      return {
        name: '',
        key: '',
        type: 'text',
        placeholder: '',
        min: undefined,
        max: undefined,
        step: undefined,
        precision: undefined,
        options: undefined
      }
    },
  
    handleIdChange(val) {
      if (val) {
        if (val !== this.data.id) {
          this.$set(this.data, 'newId', val)
        }
      } else {
        this.id = this.data.id
      }
    },
    
    handleGetPropsFromForm(form, obj, data) {
      if (form?.length) {
        form.forEach(item => {
          let value = data[item.key]
          if (value !== undefined) {
            obj[item.key] = deepClone(value)
          }
        })
      }
    },
  
    handleAddTag() {
      if (this.currentTag) {
        let tags = this.data.tags || []
        if (tags.length >= 100) {
          return
        }
        tags.push(this.currentTag)
        this.currentTag = ''
        this.$set(this.data, 'tags', tags)
      }
    },
    
    handleAddForm() {
      this.form = this.getInitFormData()
      this.editIndex = -1
      this.showFormDialog = true
    },
    
    handleEditForm(index) {
      this.form = JSON.parse(JSON.stringify(this.data.form[index]))
      this.editIndex = index
      this.showFormDialog = true
    },
    
    handleDeleteForm(index) {
      this.data.form.splice(index, 1)
    },
  
    handleAddOption() {
      let options = this.form.options || []
      options.push({value: '', label: ''})
      this.$set(this.form, 'options', options)
    },
    
    handleDeleteOption(index) {
      let options = this.form.options || []
      options.splice(index, 1)
      this.$set(this.form, 'options', options)
    },
    
    handleSaveFormConfirm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let keys = ['name', 'key', 'type', 'placeholder']
          if (this.form.type === 'number') {
            keys = [...keys, 'min', 'max', 'step', 'precision']
          } else if (this.form.type === 'select') {
            keys = [...keys, 'options']
          } else if (this.form.type === 'slider') {
            keys = [...keys, 'min', 'max', 'step']
          }
          let obj = {}
          keys.forEach(key => {
            obj[key] = this.form[key]
          })
          let form = this.data.form || []
          if (this.editIndex === -1) {
            form.push(obj)
          } else {
            form[this.editIndex] = obj
          }
          let pen = this.editor.engine.store.active.find(item => item.id === this.data.id)
          let data = {}
          propertyList.forEach(key => {
            data[key] = pen[key]
          })
          data.form = form
          this.handleGetPropsFromForm(form, data, pen)
          this.data = data
          this.showFormDialog = false
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.config-form {
  
  ::v-deep {
    
    .tag-list {
      padding: 0 16px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 8px;
      
      .el-tag {
        margin-right: 8px;
      }
    }
    
    .el-collapse-item__header {
      padding-left: 16px;
      padding-right: 8px;
      line-height: 36px;
      height: 36px;
      font-weight: bold;
    }
    
    .el-collapse-item__content {
      padding-bottom: 8px;
    }
    
    .el-form-item {
      margin-bottom: 8px;
      
      .el-form-item__label {
        font-size: 12px;
        padding-right: 8px;
        padding-left: 16px;
      }
      
      .el-form-item__content {
        font-size: 12px;
        padding-right: 12px;
      }
    }
    
    .el-input-number {
      width: 100%;
    }
    
    .el-input__inner {
      text-align: left;
      padding-left: 8px;
    }
  }
}

.data-form-row {
  display: flex;
  align-items: center;
  padding-right: 8px;
  margin-bottom: 8px;
  
  ::v-deep {
    
    .el-form-item {
      margin-bottom: 0;
      flex: 1;
  
      .el-color-picker {
        display: block;
      }
  
      .el-input-number {
        width: 100%;
      }
  
      .el-input__inner {
        text-align: left;
        padding-left: 8px;
      }
    }
    
    .el-form-item__label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 0 !important;
    }
    
    .data-form-row-icon {
      font-size: 16px;
      cursor: pointer;
    }
  }
}

.custom-input {
  width: 100%;
  
  ::v-deep {
    .el-input__inner {
      text-align: left;
    }
  }
}
</style>