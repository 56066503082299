<template>
  <el-scrollbar>
    <el-form size="mini" class="config-form" label-width="80px" label-position="left">
      <el-form-item :label="$lang.configurationEditor.maxWidth">
        <el-input-number v-model="maxWidth" :precision="0" :min="1" controls-position="right" :placeholder="$lang.configurationEditor.autoFit"/>
      </el-form-item>
      <el-form-item :label="$lang.configurationEditor.space">
        <el-input-number v-model="space" :precision="0" :min="0" controls-position="right"/>
      </el-form-item>
      <el-form-item label-width="0">
        <el-button type="primary" style="width: 100%" @click="handleLayout">{{$lang.configurationEditor.startLayout}}</el-button>
      </el-form-item>
    </el-form>
  </el-scrollbar>
</template>
<script>

export default {
  name: "LayoutConfig",
  
  data() {
    return {
      maxWidth: undefined,
      space: 30
    }
  },
  
  inject: ['eventBus'],
  
  methods: {
    handleLayout() {
      this.eventBus.$emit('layout', {
        maxWidth: this.maxWidth,
        space: this.space
      })
    }
  }
}
</script>
<style scoped lang="scss">
.config-form {
  padding: 8px 12px;
  
  ::v-deep {
    
    .el-form-item {
      margin-bottom: 8px;
      
      .el-form-item__label {
        font-size: 12px;
        padding-right: 8px;
      }
      
      .el-form-item__content {
        font-size: 12px;
      }
    }
    
    .el-input__inner {
      text-align: left;
      padding-left: 8px;
    }
  }
}
</style>