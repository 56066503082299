<template>
  <el-scrollbar>
    <el-form size="mini" class="config-form" label-width="100px" label-position="left">
      <el-collapse v-model="activeNames">
        <el-collapse-item :title="$lang.configurationEditor.positionAndSize" name="range" v-show="data.type !== 1">
          <el-form-item label="X">
            <el-input-number v-model="data.x" controls-position="right"/>
          </el-form-item>
          <el-form-item label="Y">
            <el-input-number v-model="data.y" controls-position="right"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.width">
            <el-input-number v-model="data.width" controls-position="right"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.height">
            <el-input-number v-model="data.height" controls-position="right"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.borderRadius">
            <el-input-number v-model="data.borderRadius" controls-position="right" :min="0" :max="data.width > data.height ? data.width : data.height"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.rotate">
            <el-input-number v-model="data.rotate" controls-position="right" :min="0" :max="360"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.paddingTop">
            <el-input-number v-model="data.paddingTop" controls-position="right" :min="0"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.paddingBottom">
            <el-input-number v-model="data.paddingBottom" controls-position="right" :min="0"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.paddingLeft">
            <el-input-number v-model="data.paddingLeft" controls-position="right" :min="0"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.paddingRight">
            <el-input-number v-model="data.paddingRight" controls-position="right" :min="0"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.progress">
            <el-input-number v-model="data.progress" controls-position="right" :min="0" :max="1" :step="0.1"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.progressColor">
            <color-picker v-model="data.progressColor" show-alpha/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.verticalProgress">
            <el-switch v-model="data.verticalProgress"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.flipX">
            <el-switch v-model="data.flipX"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.flipY">
            <el-switch v-model="data.flipY"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.input">
            <el-switch v-model="data.input"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.state" v-if="data.name === 'combine'">
            <el-select v-model="data.showChild" clearable>
              <template v-for="(child, index) in children">
                <el-option :value="index" :label="$lang.configurationEditor.state + (index + 1)"/>
              </template>
            </el-select>
          </el-form-item>
        </el-collapse-item>
        <el-collapse-item :title="$lang.configurationEditor.style" name="style">
          <template v-if="data.type === 1">
            <el-form-item label="起点坐标X">
              <el-input-number :value="fromAnchor.x" @change="changeProp('x', $event)" controls-position="right"/>
            </el-form-item>
            <el-form-item label="起点坐标Y">
              <el-input-number :value="fromAnchor.y" @change="changeProp('y', $event)" controls-position="right"/>
            </el-form-item>
            <el-form-item label="终点坐标X">
              <el-input-number :value="toAnchor.x" @change="changeProp('ex', $event)" controls-position="right"/>
            </el-form-item>
            <el-form-item label="终点坐标Y">
              <el-input-number :value="toAnchor.y" @change="changeProp('ey', $event)" controls-position="right"/>
            </el-form-item>
          </template>
          <el-form-item :label="$lang.configurationEditor.lineStyle">
            <ChooseLineStyle v-model="data.dash" @changeDash="$set(data, 'lineDash', $event)"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.lineType" v-if="data.type === 1">
            <ChooseLineName v-model="data.lineName" @change="eventBus.$emit('changeLineType', {id: data.id, lineName: $event})"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.lineCap">
            <el-select v-model="data.lineCap">
              <el-option value="butt" :label="$lang.configurationEditor.default"/>
              <el-option value="round" :label="$lang.configurationEditor.round"/>
              <el-option value="square" :label="$lang.configurationEditor.square"/>
            </el-select>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.lineJoin">
            <el-select v-model="data.lineJoin">
              <el-option value="miter" :label="$lang.configurationEditor.default"/>
              <el-option value="bevel" :label="$lang.configurationEditor.bevel"/>
              <el-option value="round" :label="$lang.configurationEditor.round"/>
            </el-select>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.lineGradient">
            <el-select v-model="data.strokeType">
              <el-option :value="0" :label="$lang.configurationEditor.none"/>
              <el-option :value="1" :label="$lang.configurationEditor.linearGradient"/>
            </el-select>
          </el-form-item>
          <template v-if="data.strokeType">
            <el-form-item :label="$lang.configurationEditor.startColor">
              <color-picker v-model="data.lineGradientFromColor" show-alpha/>
            </el-form-item>
            <el-form-item :label="$lang.configurationEditor.endColor">
              <color-picker v-model="data.lineGradientToColor" show-alpha/>
            </el-form-item>
            <el-form-item :label="$lang.configurationEditor.gradientAngle">
              <el-input-number v-model="data.lineGradientAngle" controls-position="right" :min="0"/>
            </el-form-item>
          </template>
          <el-form-item v-else :label="$lang.configurationEditor.color">
            <color-picker v-model="data.color" show-alpha/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.hoverColor">
            <color-picker v-model="data.hoverColor" show-alpha/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.activeColor">
            <color-picker v-model="data.activeColor" show-alpha/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.lineWidth">
            <el-input-number v-model="data.lineWidth" controls-position="right" :min="0"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.background">
            <el-select v-model="data.bkType">
              <el-option :value="0" :label="$lang.configurationEditor.none"/>
              <el-option :value="1" :label="$lang.configurationEditor.linearGradient"/>
              <el-option :value="2" :label="$lang.configurationEditor.radialGradient"/>
            </el-select>
          </el-form-item>
          <template v-if="data.bkType">
            <el-form-item :label="$lang.configurationEditor.startColor">
              <color-picker v-model="data.gradientFromColor" show-alpha/>
            </el-form-item>
            <el-form-item :label="$lang.configurationEditor.endColor">
              <color-picker v-model="data.gradientToColor" show-alpha/>
            </el-form-item>
            <el-form-item :label="$lang.configurationEditor.gradientAngle" v-if="data.bkType === 1">
              <el-input-number v-model="data.gradientAngle" controls-position="right" :min="0"/>
            </el-form-item>
            <el-form-item :label="$lang.configurationEditor.gradientRadius" v-else>
              <el-input-number v-model="data.gradientRadius" controls-position="right" :min="0"/>
            </el-form-item>
          </template>
          <el-form-item v-else :label="$lang.configurationEditor.backgroundColor">
            <color-picker v-model="data.background" show-alpha/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.hoverBackgroundColor">
            <color-picker v-model="data.hoverBackground" show-alpha/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.activeBackgroundColor">
            <color-picker v-model="data.activeBackground" show-alpha/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.alpha">
            <el-input-number v-model="data.globalAlpha" controls-position="right" :min="0" :step="0.1"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.anchorColor">
            <color-picker v-model="data.anchorColor" show-alpha/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.anchorSize">
            <el-input-number v-model="data.anchorRadius" controls-position="right" :min="0"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.shadowColor">
            <color-picker v-model="data.shadowColor" show-alpha/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.shadowBlur">
            <el-input-number v-model="data.shadowBlur" controls-position="right" :min="0"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.shadowOffsetX">
            <el-input-number v-model="data.shadowOffsetX" controls-position="right"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.shadowOffsetY">
            <el-input-number v-model="data.shadowOffsetY" controls-position="right"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.textShadow">
            <el-switch v-model="data.textHasShadow"/>
          </el-form-item>
          <template v-if="data.type === 1">
            <el-form-item :label="$lang.configurationEditor.borderColor">
              <color-picker v-model="data.borderColor" show-alpha/>
            </el-form-item>
            <el-form-item :label="$lang.configurationEditor.borderWidth">
              <el-input-number v-model="data.borderWidth" controls-position="right" :min="0"/>
            </el-form-item>
            <el-form-item :label="$lang.configurationEditor.startArrow">
              <ChooseFromArrow v-model="data.fromArrow"/>
            </el-form-item>
            <el-form-item :label="$lang.configurationEditor.startArrowColor">
              <color-picker v-model="data.fromArrowColor" show-alpha/>
            </el-form-item>
            <el-form-item :label="$lang.configurationEditor.startArrowSize">
              <el-input-number v-model="data.fromArrowSize" controls-position="right" :min="0"/>
            </el-form-item>
            <el-form-item :label="$lang.configurationEditor.endArrow">
              <ChooseToArrow v-model="data.toArrow"/>
            </el-form-item>
            <el-form-item :label="$lang.configurationEditor.endArrowColor">
              <color-picker v-model="data.toArrowColor" show-alpha/>
            </el-form-item>
            <el-form-item :label="$lang.configurationEditor.endArrowSize">
              <el-input-number v-model="data.toArrowSize" controls-position="right" :min="0"/>
            </el-form-item>
            <el-form-item :label="$lang.configurationEditor.autoFrom">
              <el-switch v-model="data.autoFrom"/>
            </el-form-item>
            <el-form-item :label="$lang.configurationEditor.autoTo">
              <el-switch v-model="data.autoTo"/>
            </el-form-item>
          </template>
        </el-collapse-item>
        <el-collapse-item :title="$lang.configurationEditor.textWord" name="text">
          <el-form-item :label="$lang.configurationEditor.font">
            <el-autocomplete
              v-model="data.fontFamily"
              :fetch-suggestions="fontFamilySearch"
            />
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.fontSize">
            <el-input-number v-model="data.fontSize" controls-position="right" :min="1"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.textColor">
            <color-picker v-model="data.textColor" show-alpha/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.hoverTextColor">
            <color-picker v-model="data.hoverTextColor" show-alpha/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.activeTextColor">
            <color-picker v-model="data.activeTextColor" show-alpha/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.textBackgroundColor">
            <color-picker v-model="data.textBackground" show-alpha/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.italic">
            <el-select v-model="data.fontStyle">
              <el-option value="normal" :label="$lang.configurationEditor.normal"/>
              <el-option value="italic" :label="$lang.configurationEditor.italic"/>
            </el-select>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.bold">
            <el-select v-model="data.fontWeight">
              <el-option value="normal" :label="$lang.configurationEditor.normal"/>
              <el-option value="bold" :label="$lang.configurationEditor.bold"/>
            </el-select>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.horizontalAlign">
            <el-select v-model="data.textAlign">
              <el-option value="left" :label="$lang.configurationEditor.alignLeft"/>
              <el-option value="center" :label="$lang.configurationEditor.center"/>
              <el-option value="right" :label="$lang.configurationEditor.alignRight"/>
            </el-select>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.verticalAlign">
            <el-select v-model="data.textBaseline">
              <el-option value="top" :label="$lang.configurationEditor.alignTop"/>
              <el-option value="middle" :label="$lang.configurationEditor.center"/>
              <el-option value="bottom" :label="$lang.configurationEditor.alignBottom"/>
            </el-select>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.lineHeight">
            <el-input-number v-model="data.lineHeight" controls-position="right" :min="0"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.textWrap">
            <el-select v-model="data.whiteSpace">
              <el-option value="" :label="$lang.configurationEditor.default"/>
              <el-option value="nowrap" :label="$lang.configurationEditor.noWrap"/>
              <el-option value="pre-line" :label="$lang.configurationEditor.preLine"/>
              <el-option value="break-all" :label="$lang.configurationEditor.breakAll"/>
            </el-select>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.textWidth">
            <el-input-number v-model="data.textWidth" controls-position="right" :min="0"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.textHeight">
            <el-input-number v-model="data.textHeight" controls-position="right" :min="0"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.offsetHorizontal">
            <el-input-number v-model="data.textLeft" controls-position="right"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.offsetVertical">
            <el-input-number v-model="data.textTop" controls-position="right"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.ellipsis">
            <el-switch v-model="data.ellipsis"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.hideText">
            <el-switch v-model="data.hiddenText"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.keepDecimal">
            <el-input-number v-model="data.keepDecimal" controls-position="right" :min="0"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.textContent">
            <el-input type="textarea" v-model="data.text" :autosize="{minRows: 1, maxRows: 10}"/>
          </el-form-item>
        </el-collapse-item>
        <el-collapse-item :title="$lang.configurationEditor.image" name="image">
          <el-form-item :label="$lang.configurationEditor.image" style="margin-top: 8px">
            <ChooseImage v-model="data.image"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.video" v-if="data.name === 'video'">
            <ChooseVideo v-model="data.video"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.backgroundImage">
            <ChooseImage v-model="data.backgroundImage"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.strokeImage">
            <ChooseImage v-model="data.strokeImage"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.imageWidth">
            <el-input-number v-model="data.iconWidth" controls-position="right" :min="0"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.imageHeight">
            <el-input-number v-model="data.iconHeight" controls-position="right" :min="0"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.keepImageRatio">
            <el-switch v-model="data.imageRatio"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.offsetHorizontal">
            <el-input-number v-model="data.iconLeft" controls-position="right"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.offsetVertical">
            <el-input-number v-model="data.iconTop" controls-position="right"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.alignWay">
            <el-select v-model="data.iconAlign">
              <el-option value="center" :label="$lang.configurationEditor.center"/>
              <el-option value="top" :label="$lang.configurationEditor.top"/>
              <el-option value="bottom" :label="$lang.configurationEditor.bottom"/>
              <el-option value="left" :label="$lang.configurationEditor.left"/>
              <el-option value="right" :label="$lang.configurationEditor.right"/>
              <el-option value="left-top" :label="$lang.configurationEditor.leftTop"/>
              <el-option value="right-top" :label="$lang.configurationEditor.rightTop"/>
              <el-option value="left-bottom" :label="$lang.configurationEditor.leftBottom"/>
              <el-option value="right-bottom" :label="$lang.configurationEditor.rightBottom"/>
            </el-select>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.imageBottom" v-if="data.image">
            <el-switch v-model="data.isBottom"/>
          </el-form-item>
        </el-collapse-item>
        <el-collapse-item :title="$lang.configurationEditor.icon" name="icon">
          <el-form-item :label="$lang.configurationEditor.icon">
            <ChooseIcon v-model="data.icon"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.iconColor">
            <color-picker show-alpha v-model="data.iconColor"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.iconSize">
            <el-input-number v-model="data.iconSize" controls-position="right"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.iconRotate">
            <el-input-number v-model="data.iconRotate" controls-position="right"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.alignWay">
            <el-select v-model="data.iconAlign">
              <el-option value="center" :label="$lang.configurationEditor.center"/>
              <el-option value="top" :label="$lang.configurationEditor.top"/>
              <el-option value="bottom" :label="$lang.configurationEditor.bottom"/>
              <el-option value="left" :label="$lang.configurationEditor.left"/>
              <el-option value="right" :label="$lang.configurationEditor.right"/>
              <el-option value="left-top" :label="$lang.configurationEditor.leftTop"/>
              <el-option value="right-top" :label="$lang.configurationEditor.rightTop"/>
              <el-option value="left-bottom" :label="$lang.configurationEditor.leftBottom"/>
              <el-option value="right-bottom" :label="$lang.configurationEditor.rightBottom"/>
            </el-select>
          </el-form-item>
        </el-collapse-item>
        <el-collapse-item :title="$lang.configurationEditor.notAllow" name="disabled">
          <el-form-item :label="$lang.configurationEditor.disableInput">
            <el-switch v-model="data.disableInput"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.disableRotate">
            <el-switch v-model="data.disableRotate"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.disableScale">
            <el-switch v-model="data.disableSize"/>
          </el-form-item>
          <el-form-item :label="$lang.configurationEditor.disableAnchor">
            <el-switch v-model="data.disableAnchor"/>
          </el-form-item>
        </el-collapse-item>
      </el-collapse>
    </el-form>
  </el-scrollbar>
</template>
<script>
import ChooseImage from "../../components/ChooseImage";
import ChooseIcon from "../../components/ChooseIcon";
import ChooseLineStyle from "../../components/ChooseLineStyle";
import ChooseLineName from "../../components/ChooseLineName";
import ChooseFromArrow from "../../components/ChooseFromArrow";
import ChooseToArrow from "../../components/ChooseToArrow";
import ChooseVideo from "../../components/ChooseVideo";
import fontFamilyList from "../../data/fontFamilyList";
import {getFromAnchor, getToAnchor, translatePoint} from "@/components/ConfigurationEngine";

const propertyList = [
  'id',
  'name',
  'type',
  'x',
  'y',
  'width',
  'height',
  'rotate',
  'borderRadius',
  'paddingTop',
  'paddingBottom',
  'paddingLeft',
  'paddingRight',
  'progress',
  'progressColor',
  'verticalProgress',
  'flipX',
  'flipY',
  'input',
  'showChild',
  
  'dash',
  'lineDash',
  'lineCap',
  'lineJoin',
  'strokeType',
  'lineGradientFromColor',
  'lineGradientToColor',
  'color',
  'hoverColor',
  'activeColor',
  'lineWidth',
  'bkType',
  'background',
  'gradientFromColor',
  'gradientToColor',
  'gradientAngle',
  'gradientRadius',
  'hoverBackground',
  'activeBackground',
  'globalAlpha',
  'anchorColor',
  'anchorRadius',
  'shadowColor',
  'shadowBlur',
  'shadowOffsetX',
  'shadowOffsetY',
  'textHasShadow',
  
  'fontFamily',
  'fontSize',
  'textColor',
  'hoverTextColor',
  'activeTextColor',
  'textBackground',
  'fontStyle',
  'fontWeight',
  'textAlign',
  'textBaseline',
  'lineHeight',
  'whiteSpace',
  'textWidth',
  'textHeight',
  'textLeft',
  'textTop',
  'ellipsis',
  'hiddenText',
  'keepDecimal',
  'text',
  
  'image',
  'video',
  'backgroundImage',
  'strokeImage',
  'iconWidth',
  'iconHeight',
  'imageRatio',
  'iconLeft',
  'iconTop',
  'iconAlign',
  'isBottom',
  
  'icon',
  'iconFamily',
  'iconSize',
  'iconRotate',
  'iconColor',
  
  'disableInput',
  'disableRotate',
  'disableSize',
  'disableAnchor',
  
  'lineName',
  'borderColor',
  'borderWidth',
  'fromArrow',
  'fromArrowColor',
  'fromArrowSize',
  'toArrow',
  'toArrowColor',
  'toArrowSize',
  'autoFrom',
  'autoTo',
]

function sleep(time) {
  return new Promise(resolve => {
    setTimeout(resolve, time)
  })
}

export default {
  name: "RenderConfig",
  components: {ChooseVideo, ChooseToArrow, ChooseFromArrow, ChooseLineName, ChooseLineStyle, ChooseIcon, ChooseImage},
  
  data() {
    return {
      activeNames: ['range', 'style', 'text', 'image', 'icon'],
      data: {},
      children: [],
      fromAnchor: {},
      toAnchor: {}
    }
  },
  
  watch: {
    data: {
      handler(val) {
        if (val.id && this.isEdit) {
          if (val.icon) {
            val.iconFamily = 'xzd-icon'
          } else {
            val.iconFamily = undefined
          }
          this.eventBus.$emit('penChange', Object.assign({}, val))
        }
      },
      deep: true
    }
  },
  
  inject: ['eventBus', 'editor'],
  
  created() {
    this.eventBus.$on('active', pens => {
      if (pens.length === 1) {
        this.isEdit = false
        let pen = pens[0]
        let data = {}
        let linePoints = ['x', 'y', 'ex', 'ey', 'width', 'height']
        propertyList.forEach(key => {
          if (pen[key] !== undefined && !(pen.type == 1 && linePoints.includes(key))) {
            data[key] = pen[key]
          }
        })
        this.data = data
        if (pen.type == 1) {
          this.fromAnchor = {
            x: pen.x,
            y: pen.y
          }
          this.toAnchor = {
            x: pen.ex,
            y: pen.ey
          }
        }
        this.children = pen.children ? JSON.parse(JSON.stringify(pen.children)) : []
        this.$nextTick(() => {
          this.isEdit = true
        })
      }
    })
  },
  
  methods: {
    async changeProp(prop, val) {
      this.isEdit = false
      let isFrom = ['x', 'y'].includes(prop)
      prop = prop.replace('e', '')
      let anchorObj = isFrom ? this.fromAnchor : this.toAnchor
      this.$set(anchorObj, prop, val)
      let {store, canvas} = this.editor.engine
      let oldVal = store.pens[this.data.id]
      store.activeAnchor = isFrom ? getFromAnchor(oldVal) : getToAnchor(oldVal)
      canvas.moveLineAnchor({
        x: anchorObj.x,
        y: anchorObj.y
      })
      canvas.patchFlagsLines.forEach(pen => canvas.initLineRect(pen))
      canvas.patchFlagsLines.clear()
      this.$nextTick(() => {
        this.isEdit = true
      })
    },
    getRealPoint(x, y) {
      let {store} = this.editor.engine
      return {
        x: x * store.data.scale + store.data.origin.x,
        y: y * store.data.scale + store.data.origin.y
      }
    },
    fontFamilySearch(queryString, callback) {
      callback(fontFamilyList.map(item => ({value: item})))
    }
  }
}
</script>
<style scoped lang="scss">
.config-form {
  
  ::v-deep {
    
    .el-collapse-item__header {
      padding-left: 16px;
      padding-right: 8px;
      line-height: 36px;
      height: 36px;
      font-weight: bold;
    }
    
    .el-collapse-item__content {
      padding-bottom: 8px;
    }
    
    .el-form-item {
      margin-bottom: 8px;
      
      .el-form-item__label {
        font-size: 12px;
        padding-right: 8px;
        padding-left: 16px;
      }
      
      .el-form-item__content {
        font-size: 12px;
        padding-right: 12px;
      }
    }
    
    .el-color-picker {
      display: block;
    }
    
    .el-input-number {
      width: 100%;
    }
    
    .el-input__inner {
      text-align: left;
      padding-left: 8px;
    }
  }
}
</style>