import request from "@/utils/request";

export function save(data) {
  return request({
    method: "post",
    url: '/system/componentManage/save',
    data,
  });
}

export function del(data) {
  return request({
    method: "post",
    url: '/system/componentManage/delete',
    data,
  });
}

export function list(data) {
  return request({
    method: "post",
    url: '/system/componentManage/getList',
    data,
  });
}

export function detail(data) {
  return request({
    method: "get",
    url: '/system/componentManage/detail',
    params: data,
  });
}

export function getTree() {
  return request({
    method: "get",
    url: '/system/componentManage/getTree'
  });
}


