<template>
  <el-dialog
    :title="id ? $t('component.edit') : $t('component.add')"
    :visible.sync="dialogVisible"
    append-to-body
    width="400px">
    <div v-loading="loading">
      <el-form
        ref="form"
        :rules="rules"
        :model="componentManage"
        label-width="auto"
        size="medium">
        <el-form-item :label="$t('component.group')" prop="groupId">
          <el-select
            v-model="componentManage.groupId"
            filterable
            clearable
            :allow-create="!id"
            :placeholder="$t('placeholder.select') + $t('component.group')"
            style="width: 100%">
            <template v-for="group in groupList">
              <el-option :value="group.id" :label="group.name"/>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('component.name')" prop="name">
          <el-input v-model="componentManage.name" :placeholder="$t('placeholder.select') + $t('component.name')"/>
        </el-form-item>
        <el-form-item :label="$t('component.icon')" prop="icon">
          <div class="component-icon" v-if="componentManage.icon">
            <i v-if="componentManage.icon.indexOf('c-icon ') === 0" :class="componentManage.icon"/>
            <img v-else :src="componentManage.icon" :alt="$t('component.icon')"/>
            <div class="delete" @click="handleDeleteIcon"/>
          </div>
          <ShowSpace v-else style="display: flex;">
            <el-button type="text" @click="showIconDialog = true">{{$t('component.chooseIcon')}}</el-button>
            <el-button type="text" @click="handleUploadIcon" :loading="uploading">{{$t('component.uploadImage')}}</el-button>
          </ShowSpace>
        </el-form-item>
        <el-form-item :label="$t('component.sort')" prop="sort">
          <el-input v-model="componentManage.sort" :placeholder="$t('placeholder.input') + $t('component.sort')"/>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog
      width="904px"
      :visible.sync="showIconDialog"
      append-to-body
      :title="$t('component.chooseIcon')">
      <div class="icon-list">
        <template v-for="icon in iconList">
          <i class="c-icon" :class="'c-' + icon" @click="componentManage.icon = 'c-icon c-' + icon; showIconDialog = false"/>
        </template>
      </div>
    </el-dialog>
    <template slot="footer">
      <el-button size="medium" type="primary" @click="onSubmit" :loading="setIng">{{$t('button.confirm')}}</el-button>
      <el-button size="medium" @click="dialogVisible = false">{{$t('button.cancel')}}</el-button>
    </template>
  </el-dialog>
</template>
<script>
import {detail, save} from '@/api/componentManage'
import {save as saveGroup} from '@/api/componentGroup'
import {chooseFile, showSuccessMsg} from "@/utils/func";
import {list as getList} from "@/api/componentGroup";
import {uploadFile} from "@/api/common";
import componentIconList from "@/components/ConfigurationEditor/data/componentIconList";

export default {
  name: "SaveComponentDialog",
  props: {
    id: {
      type: [String, Number],
      default: ''
    }
  },
  
  data() {
    return {
      dialogVisible: false,
      loading: false,
      componentManage: this.getInitFormData(),
      rules: {
        groupId: [
          {required: true, message: this.$t('placeholder.input') + this.$t('component.group'), trigger: 'blur'}
        ],
        name: [
          {required: true, message: this.$t('placeholder.input') + this.$t('component.name'), trigger: 'blur'}
        ],
        icon: [
          {required: true, message: this.$t('placeholder.select') + this.$t('component.icon'), trigger: 'blur'}
        ],
        sort: [
          {required: true, message: this.$t('placeholder.input') + this.$t('component.sort'), trigger: 'blur'}
        ]
      },
      setIng: false,
      groupList: [],
      iconList: componentIconList,
      showIconDialog: false,
      uploading: false
    }
  },
  
  methods: {
    start(data) {
      this.dialogVisible = true
      this.loading = false
      this.componentManage = this.getInitFormData()
      this.setIng = false
      if (this.id) {
        this.getInfo(this.id)
      } else {
        this.componentManage.data = data
      }
      this.getGroupList()
    },
    
    getInitFormData() {
      return {
        groupId: null,
        name: '',
        icon: '',
        data: '',
        sort: 0
      }
    },
  
    getInfo(id) {
      this.loading = true
      detail({id}).then(res => {
        this.componentManage = res.data
      }).finally(() => {
        setTimeout(() => {
          this.loading = false
        }, 500)
      })
    },
    
    getGroupList() {
      getList({
        page: 1,
        pageSize: 999999
      }).then(res => {
        if (this.id) {
          this.groupList = res.data.list
        } else {
          this.groupList = res.data.list.filter(item => item.userId !== 0)
        }
      })
    },
    
    handleUploadIcon() {
      chooseFile(file => {
        this.uploading = true
        uploadFile(file).then(res => {
          this.componentManage.icon = res.data
        }).finally(() => {
          setTimeout(() => {
            this.uploading = false
          }, 200)
        })
      }, '.jpg,.png,.gif,.svg')
    },
    
    handleDeleteIcon() {
      this.componentManage.icon = ''
    },
    
    onSubmit() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.setIng = true
          let data = JSON.parse(JSON.stringify(this.componentManage))
          if (isNaN(data.groupId)) {
            data.groupId = await saveGroup({name: data.groupId, sort: 0}).then(res => res.data)
          }
          save(data).then(() => {
            this.dialogVisible = false
            showSuccessMsg(this.$t('message.title'), (this.componentManage.id ? this.$t('message.editSuccess') : this.$t('message.addSuccess')))
            this.$emit('success', !this.componentManage.id)
          }).finally(() => {
            setTimeout(() => {
              this.setIng = false
            }, 500)
          })
        }
      });
    }
  }
}
</script>
<style scoped lang="scss">
.component-icon {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 1px solid #eeeeee;
  
  .c-icon {
    font-size: 90px;
  }
  
  img {
    max-width: 100%;
    max-height: 100%;
  }
  
  .delete {
    display: none;
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: red;
    right: -8px;
    top: -8px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 999999;
    
    &:after,
    &:before {
      content: "";
      display: block;
      width: 10px;
      height: 2px;
      background-color: white;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      transform: rotate(45deg);
    }
    
    &:after {
      transform: rotate(135deg);
    }
  }
  
  &:hover {
    .delete {
      display: block;
    }
  }
}

.icon-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  
  .c-icon {
    font-size: 32px !important;
    width: 48px;
    height: 48px;
    text-align: center;
    line-height: 48px;
    cursor: pointer;
    
    &:nth-child(18n) {
      margin-right: 0;
    }
    
    &:hover {
      background-color: #409EFF;
      color: white;
    }
  }
}
</style>