import request from "@/utils/request";

export function save(data) {
  return request({
    method: "post",
    url: '/system/page/save',
    data,
  });
}

export function del(data) {
  return request({
    method: "post",
    url: '/system/page/delete',
    data,
  });
}

export function list(data) {
  return request({
    method: "post",
    url: '/system/page/getList',
    data,
  });
}

export function detail(data) {
  return request({
    method: "get",
    url: '/system/page/detail',
    params: data,
  });
}

export function listInMarket() {
  return request({
    method: "post",
    url: '/system/page/getListInMarket'
  });
}

