<template>
  <el-scrollbar>
    <el-form size="mini" class="config-form" label-width="80px" label-position="left">
      <template v-for="property in pagePropertyList">
        <el-form-item :label="$lang.configurationEditor[property.label]">
          <component
            :is="property.component || 'el-input'"
            v-model="data[property.name]"
            v-bind="property.props">
          </component>
        </el-form-item>
      </template>
    </el-form>
  </el-scrollbar>
</template>
<script>
import pagePropertyList from "../../data/pagePropertyList";

export default {
  name: "PageConfig",
  
  data() {
    return {
      data: {},
      pagePropertyList
    }
  },
  
  watch: {
    data: {
      handler(val) {
        if (this.isEdit) {
          this.eventBus.$emit('pageChange', Object.assign({}, val))
        }
      },
      deep: true
    }
  },
  
  inject: ['eventBus'],
  
  created() {
    this.eventBus.$on('openPage', pageData => {
      this.isEdit = false
      let data = {}
      pagePropertyList.forEach(item => {
        let value = pageData[item.name]
        if (value !== undefined) {
          data[item.name] = value
        }
      })
      this.data = data
      this.$nextTick(() => {
        this.isEdit = true
      })
    })
  }
}
</script>
<style scoped lang="scss">
.config-form {
  padding: 8px 12px;
  
  ::v-deep {
  
    .el-form-item {
      margin-bottom: 8px;
      
      .el-form-item__label {
        font-size: 12px;
        padding-right: 8px;
      }
    
      .el-form-item__content {
        font-size: 12px;
      }
    }
    
    .el-color-picker {
      display: block;
    }
    
    .el-input__inner {
      text-align: left;
      padding-left: 8px;
    }
  }
}
</style>