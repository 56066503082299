import Vue from "vue";

/**
 * DOM操作相关工具
 *
 * @author zhengqiang
 * @since 2022-01-06
 */

const isServer = Vue.prototype.$isServer;
const ieVersion = isServer ? 0 : Number(document.documentMode);

function trim(string) {
    return (string || "").replace(/^[\s\uFEFF]+|[\s\uFEFF]+$/g, "");
}

function camelCaseCss(name) {
    return name.replace(/([\:\-\_]+(.))/g, function (_, separator, letter, offset) {return offset ? letter.toUpperCase() : letter;}).replace(/^moz([A-Z])/, "Moz$1");
}

/**
 * 获取元素某个样式值
 *
 * @param element DOM元素
 * @param styleName 样式名称
 * @author zhengqiang
 * @since 2022-01-14
 */
export function getStyle(element, styleName, pseudoElt = '') {
    if (!element || !styleName || isServer) {
        return;
    }
    styleName = camelCaseCss(styleName);
    //IE9以下不支持getComputedStyle函数，不支持opacity属性
    if (ieVersion < 9) {
        try {
            if (styleName === "float") {
                styleName = "styleFloat";
            }
            if (styleName === "opacity") {
                try {
                    return element.filters.item("alpha").opacity / 100;
                } catch (e) {
                    return 1.0;
                }
            }
            return element.style[styleName] || element.currentStyle ? element.currentStyle[styleName] : null;
        } catch (e) {
            return element.style[styleName];
        }
    } else {
        if (styleName === "float") {
            styleName = "cssFloat";
        }
        try {
            var computed = document.defaultView.getComputedStyle(element, pseudoElt);
            return element.style[styleName] || computed ? computed[styleName] : null;
        } catch (e) {
            return element.style[styleName];
        }
    }
}

/**
 * 设置元素的样式
 *
 * @param element DOM元素
 * @param styleName 样式名称或者样式对象
 * @param value 样式值
 * @author zhengqiang
 * @since 2022-01-14
 */
export function setStyle(element, styleName, value) {
    if (!element || !styleName || isServer) {
        return;
    }

    if (typeof styleName === "object") {
        for (var prop in styleName) {
            if (styleName.hasOwnProperty(prop)) {
                setStyle(element, prop, styleName[prop]);
            }
        }
    } else {
        styleName = camelCaseCss(styleName);
        if (styleName === "opacity" && ieVersion < 9) {
            element.style.filter = isNaN(value) ? "" : "alpha(opacity=" + value * 100 + ")";
        } else {
            element.style[styleName] = value;
        }
    }
}
/**
 * 获取元素的旋转角度，如果没有，则返回none
 *
 * @author zhengqiang
 * @since 2022-10-12
 */
export function getRotate(element) {
  if (isServer) {
    return 'none'
  }

  let transform = getStyle(element, '-webkit-transform') || getStyle(element, '-moz-transform') || getStyle(element, '-ms-transform') || getStyle(element, '-o-transform') || getStyle(element, 'transform')
  if (transform !== 'none') {
    let values = transform.split('(')[1].split(')')[0].split(',');
    let a = values[0];
    let b = values[1];
    return Math.atan2(b, a) * (180 / Math.PI);
  }
  return 'none'
}

/**
 * 获取元素到文档区域的坐标
 *
 * @author zhengqiang
 * @since 2022-10-12
 */
export function getPosition(element) {
  if (isServer) {
    return
  }

  let actualLeft = element.offsetLeft
  let actualTop = element.offsetTop
  // 取得元素的offsetParent
  let current = element.offsetParent;

  // 一直循环直到根元素
  while (current !== null) {
    actualLeft += current.offsetLeft;
    actualTop += current.offsetTop;
    current = current.offsetParent;
  }

  // 返回包含left、top坐标的对象
  return {
    left: actualLeft,
    top: actualTop
  }
}

/**
 * 获取元素是否包含css类
 *
 * @author zhengqiang
 * @since 2022-01-14
 */
export function hasClass(el, cls) {
    if (!el || !cls || isServer) {
        return false;
    }
    if (cls.indexOf(" ") !== -1) {
        throw new Error("className should not contain space.");
    }
    if (el.classList) {
        return el.classList.contains(cls);
    } else {
        return (" " + el.className + " ").indexOf(" " + cls + " ") > -1;
    }
}

/**
 * 给元素添加一个或多个css类
 *
 * @author zhengqiang
 * @since 2022-01-14
 */
export function addClass(el, cls) {
    if (!el || isServer) return;
    var curClass = el.className;
    var classes = (cls || "").split(" ");

    for (var i = 0, j = classes.length; i < j; i++) {
        var clsName = classes[i];
        if (!clsName) {
            continue;
        }

        if (el.classList) {
            el.classList.add(clsName);
        } else if (!hasClass(el, clsName)) {
            curClass += " " + clsName;
        }
    }
    if (!el.classList) {
        el.setAttribute("class", curClass);
    }
}

/**
 * 删除元素上的一个或多个css类
 *
 * @author zhengqiang
 * @since 2022-01-14
 */
export function removeClass(el, cls) {
    if (!el || !cls || isServer) return;
    var classes = cls.split(" ");
    var curClass = " " + el.className + " ";

    for (var i = 0, j = classes.length; i < j; i++) {
        var clsName = classes[i];
        if (!clsName) {
            continue;
        }

        if (el.classList) {
            el.classList.remove(clsName);
        } else if (hasClass(el, clsName)) {
            curClass = curClass.replace(" " + clsName + " ", " ");
        }
    }
    if (!el.classList) {
        el.setAttribute("class", trim(curClass));
    }
}

/**
 * 判断是否可滚动
 *
 * @author zhengqiang
 * @since 2022-03-11
 */
export function isScrollable(el, vertical) {
    if (isServer) {
        return;
    }
    const hasDirection = vertical !== null && vertical !== undefined;
    return (hasDirection ? (vertical ? getStyle(el, "overflow-y") : getStyle(el, "overflow-x")) : getStyle(el, "overflow")).match(/(scroll|auto)/);
}

/**
 * 从元素一直往上查找可滚动容器
 *
 * @author zhengqiang
 * @since 2022-03-11
 */
export function getScrollContainer(el, vertical) {
    if (isServer) {
        return;
    }
    let parent = el;
    while(parent) {
        if ([window, document, document.documentElement].includes(parent)) {
            return window;
        }
        if (isScrollable(el, vertical)) {
            return parent;
        }
        parent = parent.parentNode;
    }
    return parent;
}

/**
 * 获取滚动条宽度
 *
 * @author zhengqiang
 * @since 2022-03-25
 */
let scrollBarWidth = undefined;
export function getScrollBarWidth() {
    if (scrollBarWidth === undefined) {
        let wrap = document.createElement("div");
        wrap.className = "cat-scroll-view__wrap";
        setStyle(wrap, {
            visibility: "hidden",
            width: "100px",
            position: "absolute",
            top: "-9999px"
        });
        document.body.appendChild(wrap);

        let inner = document.createElement("div");
        setStyle(inner, "width", "100%");
        document.body.appendChild(inner);
        wrap.appendChild(inner);

        scrollBarWidth = wrap.offsetWidth - inner.offsetWidth;
        document.body.removeChild(wrap);
    }
    return scrollBarWidth;
}

/**
 * 判断元素是否展示在容器范围内
 *
 * @author zhengqiang
 * @since 2022-03-11
 */
export function isInContainer(el, container) {
    if (!el || isServer) {
        return false;
    }

    const elRect = el.getBoundingClientRect();
    let containerRect;
    if ([window, document, document.documentElement, null, undefined].includes(container)) {
        containerRect = {
            top: 0,
            right: window.innerWidth,
            bottom: window.innerHeight,
            left: 0
        };
    } else {
        containerRect = container.getBoundingClientRect();
    }

    return elRect.top < containerRect.bottom && elRect.bottom > containerRect.top && elRect.right > containerRect.left && elRect.left < containerRect.right;
}

/**
 * 为元素添加事件监听
 *
 * @author zhengqiang
 * @since 2022-03-11
 */
export function on(el, event, handler) {
    if (el && event && handler) {
        if (!isServer && document.addEventListener) {
            el.addEventListener(event, handler, false);
        } else {
            el.attachEvent("on" + event, handler);
        }
    }
}

/**
 * 为元素添加只执行一次的事件监听
 *
 * @author zhengqiang
 * @since 2022-03-11
 */
export function once(el, event, handler) {
    var listener = function() {
        if (handler) {
            handler.apply(this, arguments);
        }
        off(el, event, listener);
    };
    on(el, event, listener);
}

/**
 * 移除元素的事件监听
 *
 * @author zhengqiang
 * @since 2022-03-11
 */
export function off(el, event, handler) {
    if (el && event && handler) {
        if (!isServer && document.removeEventListener) {
            el.removeEventListener(event, handler, false);
        } else {
            el.detachEvent("on" + event, handler);
        }
    }
}