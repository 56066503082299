<template>
  <div v-show="visible" class="menu" oncontextmenu="return false">
    <div class="menu-item" :class="{disabled: !hasActive}" @click="handleItemClick('top', $event)">{{$lang.configurationEditor.putTop}}</div>
    <div class="menu-item" :class="{disabled: !hasActive}" @click="handleItemClick('bottom', $event)">{{$lang.configurationEditor.putBottom}}</div>
    <div class="menu-item" :class="{disabled: !hasActive}" @click="handleItemClick('up', $event)">{{$lang.configurationEditor.putUp}}</div>
    <div class="menu-item" :class="{disabled: !hasActive}" @click="handleItemClick('down', $event)">{{$lang.configurationEditor.putDown}}</div>
    <div class="menu-line"/>
    <template v-if="isMulti">
      <div class="menu-item" @click="handleItemClick('combine', $event)">{{$lang.configurationEditor.combine}}</div>
      <div class="menu-item" @click="handleItemClick('combineState', $event)">{{$lang.configurationEditor.combineState}}</div>
    </template>
    <div class="menu-item"v-if="isCombine" @click="handleItemClick('unCombine', $event)">{{$lang.configurationEditor.unCombine}}</div>
    <div class="menu-item" v-if="isLocked" @click="handleItemClick('unlock', $event)">{{$lang.configurationEditor.unlock}}</div>
    <div class="menu-item" v-else :class="{disabled: !hasActive}" @click="handleItemClick('lock', $event)">{{$lang.configurationEditor.lock}}</div>
    <div class="menu-line"/>
    <div class="menu-item" :class="{disabled: !hasActive}" @click="handleItemClick('delete', $event)">{{$lang.configurationEditor.delete}}</div>
    <template v-if="isLine">
      <div class="menu-line"/>
      <div class="menu-item" @click="handleItemClick('toNode', $event)">{{$lang.configurationEditor.changeToNode}}</div>
    </template>
    <div class="menu-line"/>
    <div class="menu-item" :class="{disabled: !canUndo}" @click="handleItemClick('undo', $event)">
      <div>{{$lang.configurationEditor.undo}}</div>
      <div>Ctrl + Z</div>
    </div>
    <div class="menu-item" :class="{disabled: !canRedo}" @click="handleItemClick('redo', $event)">
      <div>{{$lang.configurationEditor.redo}}</div>
      <div>Shift + Z</div>
    </div>
    <div class="menu-line"/>
    <div class="menu-item" :class="{disabled: !hasActive}" @click="handleItemClick('cut', $event)">
      <div>{{$lang.configurationEditor.cut}}</div>
      <div>Ctrl + X</div>
    </div>
    <div class="menu-item" :class="{disabled: !hasActive}" @click="handleItemClick('copy', $event)">
      <div>{{$lang.configurationEditor.copy}}</div>
      <div>Ctrl + C</div>
    </div>
    <div class="menu-item" :class="{disabled: !canPaste}" @click="handleItemClick('paste', $event)">
      <div>{{$lang.configurationEditor.paste}}</div>
      <div>Ctrl + V</div>
    </div>
  </div>
</template>
<script>
import {hasClass} from "@/utils/dom";

export default {
  name: "ContextMenu",
  
  model: {
    prop: 'visible',
    event: 'change'
  },
  
  props: {
    x: Number,
    y: Number,
    visible: Boolean
  },
  
  data() {
    return {
      activePens: [],
      canUndo: false,
      canRedo: false,
      canPaste: false
    }
  },
  
  computed: {
    hasActive() {
      return this.activePens.length > 0
    },
    isMulti() {
      return this.activePens.length > 1
    },
    isLine() {
      return this.hasActive && !this.isMulti && this.activePens[0].type === 1
    },
    isLocked() {
      for (let i = 0; i < this.activePens.length; i++) {
        let pen = this.activePens[i]
        if (pen.locked) {
          return true
        }
      }
      return false
    },
    isCombine() {
      return this.hasActive && !this.isMulti && (this.activePens[0].children?.length > 0)
    }
  },
  
  watch: {
    visible(val) {
      if (val) {
        let engine = this.$parent.engine
        let {histories = [], historyIndex} = engine.store
        historyIndex = historyIndex ?? -1
        this.canUndo = historyIndex > -1
        this.canRedo = historyIndex < histories.length - 1
        this.canPaste = false
        if (navigator.clipboard) {
          navigator.clipboard.readText().then(res => {
            if (res) {
              try {
                JSON.parse(res)
                this.canPaste = true
              } catch (e) { }
            }
          }).catch(() => {
            //ignore
          })
        } else {
          this.canPaste = true
        }
      }
    }
  },
  
  inject: ['eventBus'],
  
  created() {
    this.eventBus.$on('active', pens => {
      this.activePens = pens.map(({id, type, locked, children}) => ({id, type, locked, children}))
    })
  },
  
  updated() {
    if (this.visible) {
      this.$el.style.left = this.x + 'px'
      if (this.y + this.$el.offsetHeight > document.body.clientHeight) {
        this.$el.style.bottom = '0px'
        this.$el.style.top = 'unset'
      } else {
        this.$el.style.top = this.y + 'px'
        this.$el.style.bottom = 'unset'
      }
    }
  },
  
  methods: {
    handleItemClick(item, e) {
      let target = hasClass(e.target, 'menu-item') ? e.target : e.target.parentNode
      if (!hasClass(target, 'disabled')) {
        this.$emit('change', false)
        this.handleMenuClick(item)
      }
    },
  
    handleMenuClick(item) {
      let engine = this.$parent.engine
      switch (item) {
        case 'top':
          engine.store.active.forEach(pen => {
            engine.top(pen)
          })
          break
        case 'bottom':
          engine.store.active.forEach(pen => {
            engine.bottom(pen)
          })
          break
        case 'up':
          engine.store.active.forEach(pen => {
            engine.up(pen)
          })
          break
        case 'down':
          engine.store.active.forEach(pen => {
            engine.down(pen)
          })
          break
        case 'combine':
          engine.combine(engine.store.active)
          break
        case 'combineState':
          engine.combine(engine.store.active, 0)
          break
        case 'unCombine':
          engine.unCombine(engine.store.active[0])
          break
        case 'lock':
          engine.store.active.forEach(pen => {
            engine.setValue({id: pen.id, locked: 2})
          })
          break
        case 'unlock':
          engine.store.active.forEach(pen => {
            engine.setValue({id: pen.id, locked: undefined})
          })
          break
        case 'toNode':
          engine.store.active.forEach(pen => {
            engine.setValue({id: pen.id, type: 0})
          })
          break
        case 'delete':
          engine.delete(engine.store.active)
          break
        case 'undo':
          engine.undo()
          break
        case 'redo':
          engine.redo()
          break
        case 'cut':
          engine.cut()
          break
        case 'copy':
          engine.copy()
          break
        case 'paste':
          engine.paste()
          break
      }
      engine.render()
    },
  }
}
</script>
<style scoped lang="scss">
.menu {
  background-color: white;
  padding: 4px 0;
  position: fixed;
  z-index: 999999;
  width: 200px;
  box-shadow: 0 2px 8px #00000026;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently not supported by any browser */
  
  .menu-item {
    font-size: 14px;
    color: black;
    padding: 5px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    
    &:not(.disabled) {
      cursor: pointer;
      
      &:hover {
        background-color: #F1F2F3;
      }
    }
    
    &.disabled {
      color: #999999;
      cursor: not-allowed;
    }
  }
  
  .menu-line {
    border-bottom: 1px solid #eeeeee;
    margin: 4px 0;
  }
}
</style>