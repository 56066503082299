<template>
  <div>
    <el-button type="text" @click="handleShowDialog">{{$lang.button.edit}}</el-button>
    <el-drawer
      size="240px"
      :visible.sync="showDialog"
      destroy-on-close
      :with-header="false"
      direction="rtl">
      <div class="header">{{$lang.configurationEditor.animationFrame}}</div>
      <div class="add-button">
        <el-button type="primary" size="mini" @click="handleAddFrame">{{$lang.configurationEditor.addAnimationFrame}}</el-button>
      </div>
      <el-scrollbar class="frame-list">
        <el-collapse v-model="activeNames">
          <template v-for="(frame, index) in frameList">
            <el-collapse-item :name="'frame-' + index">
              <div slot="title" class="frame-title">
                <div>{{$lang.configurationEditor.animationFrame}} {{index + 1}}/{{frameList.length}}</div>
                <el-popconfirm :title="$lang.confirm.delete" @confirm="handleDeleteFrame(index)">
                  <i slot="reference" class="c-icon c-shanchu" @click.stop/>
                </el-popconfirm>
              </div>
              <template v-for="property in framePropertyList">
                <el-form-item :label="$lang.configurationEditor[property.label]" v-if="property.isShow ? property.isShow(this, frame) : true">
                  <el-select
                    v-if="property.component === 'el-select'"
                    v-model="frame[property.name]"
                    v-bind="property.props"
                    :placeholder="property.props && property.props.placeholder ? $lang.configurationEditor[property.props.placeholder] : undefined"
                    v-on="createEvents(property.on, frame)">
                    <template v-for="option in property.options || []">
                      <el-option :value="option.value" :label="$lang.configurationEditor[option.label]"/>
                    </template>
                  </el-select>
                  <component
                    v-else
                    :is="property.component"
                    v-model="frame[property.name]"
                    v-bind="property.props"
                    v-on="createEvents(property.on, frame)"
                    :placeholder="property.props && property.props.placeholder ? $lang.configurationEditor[property.props.placeholder] : undefined"
                  />
                </el-form-item>
              </template>
            </el-collapse-item>
          </template>
        </el-collapse>
      </el-scrollbar>
    </el-drawer>
  </div>
</template>
<script>
import ChooseLineStyle from "@/components/ConfigurationEditor/components/ChooseLineStyle";
import animationFramePropertyList from "@/components/ConfigurationEditor/data/animationFramePropertyList";

export default {
  name: "EditAnimationFrames",
  components: {ChooseLineStyle},
  model: {
    prop: 'value',
    event: 'change'
  },
  
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  
  data() {
    return {
      showDialog: false,
      frameList: [],
      isEdit: false,
      activeNames: [],
      framePropertyList: animationFramePropertyList
    }
  },
  
  watch: {
    frameList: {
      handler(val) {
        if (this.isEdit) {
          let arr = JSON.parse(JSON.stringify(val))
          let duration = 0
          arr = arr.map(item => {
            let obj = {}
            Object.keys(item).forEach(key => {
              if (item[key] !== undefined && item[key] !== '') {
                obj[key] = item[key]
              }
            })
            duration += item.duration
            return obj
          })
          this.$emit('change', arr)
          this.$emit('changeDuration', duration)
        }
      },
      deep: true
    }
  },
  
  methods: {
    handleShowDialog() {
      this.isEdit = false
      this.activeNames = []
      this.frameList = JSON.parse(JSON.stringify(this.value || []))
      this.showDialog = true
      this.$nextTick(() => {
        this.isEdit = true
      })
    },
    
    handleAddFrame() {
      this.frameList.push({
        duration: 200,
        visible: true
      })
    },
    
    handleDeleteFrame(index) {
      this.frameList.splice(index, 1)
    },
    
    createEvents(on, frame) {
      let obj = {}
      if (on) {
        Object.keys(on).forEach(key => {
          obj[key] = (...args) => {
            on[key](...[this, frame, ...args])
          }
        })
      }
      return obj
    }
  }
}
</script>
<style scoped lang="scss">
.header {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-bottom: 1px solid #eeeeee;
  font-size: 16px;
  font-weight: bold;
  color: #000000;
}

.add-button {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.frame-list {
  height: calc(100% - 110px);
  
  ::v-deep {
    > .el-scrollbar__wrap {
      overflow-x: auto;
    }
    
    .el-collapse-item__header {
      padding-left: 16px;
      padding-right: 8px;
      line-height: 36px;
      height: 36px;
      font-weight: bold;
    }
    
    .el-collapse-item__content {
      padding-bottom: 8px;
    }
    
    .el-form-item {
      margin-bottom: 8px;
      
      .el-form-item__label {
        font-size: 12px;
        padding-right: 8px;
        padding-left: 16px;
      }
      
      .el-form-item__content {
        font-size: 12px;
        padding-right: 12px;
      }
    }
    
    .el-color-picker {
      display: block;
    }
    
    .el-input-number {
      width: 100%;
    }
    
    .el-input__inner {
      text-align: left;
      padding-left: 8px;
    }
  }
}

.frame-title {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 16px;
  
  ::v-deep {
    .c-icon {
      font-size: 14px;
    }
  }
}
</style>